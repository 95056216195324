import ApplicationController from "application_controller";

/*
 * after-signup--step-users
 */
export default class extends ApplicationController {
  static targets = [
    "selUsers",
    "selAccessLevel",
    "tbody",
    "count",
    "hiddenMembers",
  ];

  static values = {
    levels: Array,
    members: String,
  };

  initialize() {
    this._members = [];
    this._levelNames = {};
  }

  connect() {
    this.levelsValue.forEach((x) => {
      const [title, value] = x;
      this._levelNames[value] = title;
    });

    const members = (this.membersValue || "")
      .split(",")
      .map((x) => x.split(":"))
      .filter((x) => x.length === 2);

    if (members.length) {
      this._members = members;
      this.renderMembers();
    }
  }

  // events -------------------------------------------------------------------

  onAdd = () => {
    const users = $(this.selUsersTarget)
      .val()
      .map((x) => x.split(":")[1]);
    const accessLevel = $(this.selAccessLevelTarget).val();

    users.forEach((x) => {
      const email = x.trim();
      if (!this._members.some((m) => m[0] === email))
        this._members.push([x, accessLevel]);
    });

    this.saveToHiddenField();
    $(this.selUsersTarget).val(null).trigger("change");
  };

  onRemove = (e) => {
    const idx = parseInt(e.srcElement.closest("button").dataset.id, 10) - 1;
    this._members.splice(idx, 1);

    this.saveToHiddenField();
  };

  // methods: private ..........................................................

  saveToHiddenField = () => {
    this.hiddenMembersTarget.value = this._members
      .map((x) => {
        return `${x[0]}:${x[1]}`;
      })
      .join(",");

    this.renderMembers();
  };

  renderMembers = () => {
    this.countTarget.innerHTML = this._members.length + 1;

    this.tbodyTarget.querySelectorAll("tr.js-new-member").forEach((x) => {
      this.tbodyTarget.removeChild(x);
    });

    let i = 0;
    this._members.forEach((x) => {
      i += 1;
      this.renderMember(i, x[0], this._levelNames[x[1]]);
    });
  };

  renderMember = (id, email, accessLevel) => {
    const tr = document.createElement("tr");
    tr.className = "js-new-member";
    tr.innerHTML = `
    <td>
      <div class="inline-flex-row">
        <div class="inline-flex-column">
          <span><b>${email}</b></span>
        </div>
      </div>
    </td>

    <td class="d-flex flex-row-reverse">
      <button type="button"
        class="btn btn-danger align-self-center m-0 ml-sm-3 waves-effect waves-light"
        data-action="click->after-signup--step-users#onRemove"
        data-id="${id}">
        <i aria-hidden="true" data-hidden="true" class="fa fa-trash"></i>
      </button>

      <h6>
        <span class="badge badge-pill badge-soft-dark align-self-center">
          ${accessLevel}
        </span>
      </h6>

    </td>
    `;

    this.tbodyTarget.appendChild(tr);
  };
}
