import ApplicationController from "application_controller";
import Swal from "sweetalert2";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = ["btnAddProductItem", "template", "wrapper", "editButton"];

  initialize() {
    this.showMode = this.data.get("showMode") === "true";
    this.showSubmit = this.data.get("submit") === "true";
    this._originalForms = {};
  }

  connect() {
    if (!this.showMode) this.initEditMode();
  }

  // events -------------------------------------------------------------------

  onAddItem(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.btnAddProductItemTarget.insertAdjacentHTML("beforebegin", content);

    if (this.showMode) this.element.classList.add("js-editing");

    this.highlightLast(
      this.wrapperTarget.querySelectorAll(".product-item--editing")
    );
  }

  onCancel(e) {
    e.preventDefault();

    const item = e.target.closest(".product-item");
    if (this.showMode) this.element.classList.remove("js-editing");

    if (this.newRecord(item)) {
      this.highlightRemoval(item, () => item.remove());
      return;
    }

    item.classList.remove("product-item--editing");
    this.highlight(item);

    const id = this._itemId(item);

    if (!this._originalForms[id]) {
      console.warn("onCancel -- _originalForms missing", { id });
      return;
    }

    this._restoreOriginalForm(item);
  }

  onEditItem(e) {
    e.preventDefault();

    const item = e.target.closest(".product-item");
    item.classList.add("product-item--editing");

    this._saveOriginalForm(item);
    this._restoreOriginalForm(item); // to reinitialize select2
    e.target.display = "none";

    if (this.showMode) this.element.classList.add("js-editing");
    this.highlight(item);
  }

  onRemoveItem(e) {
    e.preventDefault();

    const swalOptions = {
      title: I18n.t("delete_confirm"),
      html: e.target.dataset.title,
      showCancelButton: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      focusCancel: true,
      customClass: {
        container: `DataConfirmModal DataConfirmModal--danger`,
        title: `card-header bg-danger text-white my-0 w-100`,
        confirmButton: `btn btn-danger waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value) return;
      const item = e.target.closest(".product-item");
      const id = this._itemId(item);

      this.highlightRemoval(item, () => {
        if (id) {
          // ajax remove
          item.querySelector("input[name*='_destroy']").value = 1;
          item.style.display = "none";
          if (this.showMode) this._submitForm(item);
        } else {
          // remove from dom only
          item.parentNode.removeChild(item);
        }
      });
    });
  }

  // methods: private ..........................................................

  _saveOriginalForm(item) {
    const id = this._itemId(item);

    if (this._originalForms[id]) return;

    const form = item.querySelector(".product-item-form");

    const controller = this.application.getControllerForElementAndIdentifier(
      form.querySelector("select"),
      "select-tree"
    );

    if (controller) controller.disconnect();

    this._originalForms[id] = form.innerHTML;
  }

  _restoreOriginalForm(item) {
    const id = this._itemId(item);
    item.querySelector(".product-item-form").innerHTML = this._originalForms[
      id
    ];
  }

  _submitForm(item) {
    item.closest(".product-form").submit();
  }

  _itemId(item) {
    return item.getAttribute("data-item-id");
  }

  newRecord(item) {
    return item.hasAttribute("data-item-new");
  }

  initEditMode() {
    this.editButtonTargets.forEach((x) => {
      const item = x.closest(".product-item");
      item.classList.add("product-item--editing");
    });
  }
}
