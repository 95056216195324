import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["country", "timezone"];

  connect() {
    this._url = this.data.get("url");
    this._guessTimeZone();
  }

  // methods - private --------------------------------------------------------

  _guessTimeZone() {
    const val = Intl.DateTimeFormat().resolvedOptions().timeZone;

    fetch("https://extreme-ip-lookup.com/json/")
      .then((res) => res.json())
      .then((response) => {
        return response.country;
      })
      .catch(() => {
        return null;
      })
      .then((country) => {
        Rails.ajax({
          type: "GET",
          url: this._url,
          data: new URLSearchParams({
            value: val,
            country: country || "",
          }).toString(),
          success: (data) => {
            this._setSelectVal(data.result);
          },
        });
      });
  }

  _setSelectVal = (val) => {
    if (!val) return;

    // timezone
    let $el = $(this.timezoneTarget);
    $el.val(val.timezone);
    $el.trigger("change");

    // country
    $el = $(this.countryTarget);
    $el.val(val.country);
    $el.trigger("change");
  };
}
