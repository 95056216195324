import { Controller } from "stimulus";
import Velocity from "velocity-animate";

export default class extends Controller {
  get isTurbolinksPreview() {
    return document.body.hasAttribute("data-turbolinks-preview");
  }

  get classList() {
    return this.element.classList;
  }

  bindElementController = () => {
    this.element.controller = this;
  };

  isBlank = (str) => {
    return !str || /^\s*$/.test(str);
  };

  parseBool = (val, defaultVal = null) => {
    if (val === "true") return true;
    if (val === "false") return false;
    return defaultVal;
  };

  formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    return window.formatMoney(amount, decimalCount, decimal, thousands);
  };

  lastFromNodeList(nodeList) {
    if (!nodeList) return nodeList;

    return nodeList[nodeList.length - 1];
  }

  updateRailsFieldsNames = (parentEl, startPattern, newIdx) => {
    const reStr = startPattern.replace(/\[/g, "\\[").replace(/\]/g, "\\]");
    const re = new RegExp(`(${reStr})(.*?)(\\].*)`);

    const startIdx = Math.floor(Date.now() / 1000) * 1000; // timestamp seconds
    parentEl.querySelectorAll("[name]").forEach((x) => {
      this.updateRailsFieldName(re, x, newIdx, startIdx);
    });
  };

  updateRailsFieldName(re, el, idx, startIdx) {
    const m = el.name.match(re);
    if (!m) return null;

    const oldIdx = m[2];
    if (parseInt(oldIdx, 10) > 1000000) return null; // this field has already been renamed

    const newIdx = idx + startIdx; // to not overlap with previous data

    const oldName = el.name;
    // const oldId = el.id;
    const newName = el.name.replace(re, `$1${newIdx - 1}$3`);

    if (newName !== oldName) {
      el.name = newName;
      if (el.id) {
        // rename ID
        const newId = newName
          .replace(/\]\[/g, "_")
          .replace(/\[/g, "_")
          .replace(/\]/g, "_");
        el.id = newId;
      }
      return newName;
    }
    return null;
  }

  // Animations ...............................................................

  highlight(element, callback = null) {
    Velocity(element, { scale: 1.02 }, 100).then(
      Velocity(element, { scale: 1 }, 200).then(() => {
        if (callback) callback();
      })
    );
  }

  highlightRemoval(element, callback = null) {
    this.highlightSlideUp(element, callback);
  }

  highlightSlideUp(element, callback = null) {
    Velocity(element, { opacity: 0.4 }, 100).then(
      Velocity(element, "slideUp", 250).then(() => {
        if (callback) callback();
      })
    );
  }

  highlightSlideDown(element, callback = null) {
    Velocity(element, "slideUp", 0).then(() => {
      element.style.opacity = "1";
      element.classList.remove("d-none");

      Velocity(element, "slideDown", 250).then(() => {
        if (callback) callback();
      });
    });
  }

  highlightToggle(element, visible, callback = null) {
    if (visible) {
      element.style.opacity = "1";
      element.classList.remove("d-none");

      Velocity(element, "slideDown", 0).then(() => {
        this.highlight(element, callback);
      });
    } else this.highlightSlideUp(element, callback);
  }

  highlightLast(nodeList, callback = null) {
    this.highlight(this.lastFromNodeList(nodeList), callback);
  }

  highlightText(element, callback = null) {
    Velocity(element, { rotateY: 55 }, 100).then(
      Velocity(element, { rotateY: 0 }, 50).then(() => {
        if (callback) callback();
      })
    );
  }

  scrollTo = (el) => {
    window.scroll(0, this.findPos(el));
    setTimeout(() => this.highlight(el), 300);
  };

  findPos = (el, middle = false) => {
    let curtop = 0;
    if (el.offsetParent) {
      let newEl = el;
      do {
        curtop += newEl.offsetTop;
        newEl = newEl.offsetParent;
      } while (newEl);
    }

    return middle ? curtop - window.screen.height / 2 : curtop - 100;
  };
}
