import ApplicationController from "application_controller";
import Swal from "sweetalert2";
import I18n from "lib/i18n";
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static values = { id: Number, url: String, title: String };

  connect() {
    this.element.addEventListener("click", this.onShow);
  }

  initialize() {
    this._id = this.idValue;
    this._url = this.urlValue;
  }

  onShow = (e) => {
    e.preventDefault();
    const _title = this.titleValue;
    Rails.ajax({
      type: "GET",
      url: this._url,
      data: "",
      error: (er) => {
        console.warn("notes::create -- error", { er });
        this.uiLoading(I18n.t("error_api"));
      },
      success(doc) {
        const html = doc.getElementById("tplCreateNote").innerHTML;
        const swalOptions = {
          title: I18n.t("create_note.title", { title: _title }),
          html,
          showCancelButton: false,
          showConfirmButton: false,
          customClass: {
            container: "DataConfirmModal DataConfirmModal--create-note",
            title: "card-header bg-dark text-white my-0 w-100",
            confirmButton: "btn btn-primary waves-effect waves-light",
            cancelButton: "btn btn-light waves-effect waves-light",
            actions: "card-footer bg-light m-0",
          },
        };
        Swal.fire(swalOptions).then(() => {
          window.tinymce.remove();
        });
      },
    });
  };
}
