import ApplicationController from "application_controller";
import Readmore from "readmore-js";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  connect() {
    this._readmore = new Readmore(this.element, {
      collapsedHeight: 80,
      moreLink: `<a href="#" class="readmore readmore--more"><span><i class="fa fa-angle-double-down"></i> ${I18n.t(
        "common.more_content"
      )}</span></a>`,
      lessLink: `<a href="#" class="readmore readmore--less"><span><i class="fa fa-angle-double-up"></i> ${I18n.t(
        "common.less_content"
      )}</span></a>`,
    });
  }

  disconnect() {
    if (this._readmore) this._readmore.destroy();
  }
}
