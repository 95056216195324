import ApplicationController from "application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  static targets = [
    "total",
    "totals",
    "taxRow",
    "taxTitle",
    "taxAmount",
    "totalGrossRow",
    "totalGrossAmount",
  ];

  static values = {
    plusTax: Object,
    disableTax: Boolean,
  };

  initialize() {
    this._connected = false;
    this._debouncedUpdate = debounced.debounce(this.onUpdate, { wait: 200 });
    this._oldTax = null;
  }

  connect() {
    this.taxRate = 0;
    this.taxTotal = 0;
    this.grossTotal = 0;

    this._connected = true;
    this.updateTax();
    this.element.addEventListener("total:changed", this._debouncedUpdate);
  }

  disconnect() {
    this.element.removeEventListener("total:changed", this._debouncedUpdate);
  }

  plusTaxValueChanged() {
    if (!this._connected) return;
    this.updateTax();
  }

  updateTax = () => {
    if (this.disableTaxValue) return;

    if (this._oldTax === JSON.stringify(this.plusTaxValue)) return;
    this._oldTax = JSON.stringify(this.plusTaxValue);

    const hasVal = !$.isEmptyObject(this.plusTaxValue);

    if (hasVal)
      this.taxRate =
        this.plusTaxValue.amount || this.plusTaxValue.dataset.amount || 0;
    else this.taxRate = 0;

    this._debouncedUpdate();
  };

  onUpdate = () => {
    this.recompute();
  };

  recompute = () => {
    this.grossTotal = 0;
    this.taxTotal = 0;

    this.totalsTargets.forEach((x) => {
      const destroyNode = x
        .closest("tr")
        .querySelector("input[name*='_destroy']");
      if (!destroyNode || destroyNode.value !== "1")
        this.grossTotal += parseFloat(x.dataset.value || "0");
    });

    if (this.taxRate > 0)
      this.taxTotal = (this.grossTotal * this.taxRate) / 100.0;

    this.updateUI();
  };

  updateUI = () => {
    const hasTax = this.taxRate > 0;
    const grossTotalStr = this.m(this.grossTotal);

    this.totalGrossRowTarget.classList.toggle("d-none", !hasTax);
    this.taxRowTarget.classList.toggle("d-none", !hasTax);

    if (hasTax) {
      this.totalGrossAmountTarget.textContent = grossTotalStr;
      this.taxAmountTarget.textContent = this.m(this.taxTotal);
      this.taxTitleTarget.textContent = this.plusTaxValue.text;
      this.totalTarget.textContent = this.m(this.grossTotal + this.taxTotal);
    } else {
      this.totalTarget.textContent = grossTotalStr;
    }

    this.highlightText(this.totalTarget);
  };

  m = (amount) => {
    return this.formatMoney(amount / 100);
  };
}
