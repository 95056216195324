import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._tooltips = $(this.element).tooltip({ html: true });
  }

  disconnect() {
    this._tooltips.tooltip("dispose");
  }
}
