import Swal from "sweetalert2";
import ApplicationController from "application_controller";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = ["btnAddTerm", "template", "wrapper", "editButton"];

  initialize() {
    this.showMode = this.data.get("showMode") === "true";
    this._originalForms = {};
  }

  connect() {
    if (!this.showMode) this.initEditMode();
  }

  // events -------------------------------------------------------------------

  onAddTerm(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.wrapperTarget.insertAdjacentHTML("beforeend", content);
    if (this.showMode) this.element.classList.add("js-editing");

    this.highlight(this.wrapperTarget.lastElementChild);
  }

  onCancel(e) {
    e.preventDefault();

    const item = e.target.closest(".termgroup-term");

    item.classList.remove("termgroup-term--editing");
    if (this.showMode) this.element.classList.remove("js-editing");

    if (this.newRecord(item)) {
      this.highlightRemoval(item, () => {
        item.remove();
      });
      return;
    }

    const id = this._termId(item);

    if (!this._originalForms[id]) {
      console.warn("onCancel -- _originalForms missing", { id });
      return;
    }

    item.querySelector(".termgroup-term-form").innerHTML = this._originalForms[
      id
    ];
  }

  onEditTerm(e) {
    e.preventDefault();

    const item = e.target.closest(".termgroup-term");
    item.classList.add("termgroup-term--editing");

    this._saveOriginalForm(item);
    e.target.display = "none";
    if (this.showMode) this.element.classList.add("js-editing");

    this.highlight(item);
  }

  onRemoveTerm(e) {
    e.preventDefault();

    const swalOptions = {
      title: e.target.dataset.confirmTitle,
      html: e.target.dataset.title,
      showCancelButton: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      focusCancel: true,
      customClass: {
        container: `DataConfirmModal DataConfirmModal--danger`,
        title: `card-header bg-danger text-white my-0 w-100`,
        confirmButton: `btn btn-danger waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value) return;
      const item = e.target.closest(".termgroup-term");
      const id = this._termId(item);

      this.highlightRemoval(item, () => {
        if (id) {
          // ajax remove
          item.querySelector("input[name*='_destroy']").value = 1;
          item.style.display = "none";
          if (this.showMode) this._submitForm(item);
        } else {
          // remove from dom only
          item.parentNode.removeChild(item);
        }
      });
    });
  }

  // methods: private ..........................................................

  _saveOriginalForm(item) {
    const id = item.getAttribute("data-term-id");

    if (this._originalForms[id]) return;

    this._originalForms[id] = item.querySelector(
      ".termgroup-term-form"
    ).innerHTML;
  }

  _submitForm(item) {
    item.closest(".termgroup-form").submit();
  }

  _termId(item) {
    return item.getAttribute("data-term-id");
  }

  newRecord(item) {
    return item.hasAttribute("data-term-new");
  }

  initEditMode() {
    this.editButtonTargets.forEach((x) => {
      const item = x.closest(".termgroup-term");
      item.classList.add("termgroup-term--editing");
    });
  }
}
