import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["validUntil", "date"];

  // events -------------------------------------------------------------------

  autoFillValidUntil(e) {
    const numTime = parseInt(e.target.dataset.number, 10);
    if (!numTime) return;
    const now = new Date();
    if (e.target.dataset.week) now.setDate(now.getDate() + numTime * 7);
    else now.setMonth(now.getMonth() + numTime);
    this.validUntilTarget.value = now.toISOString().substring(0, 10);
  }

  autoFillDate() {
    const now = new Date().toISOString().substring(0, 10);
    this.dateTarget.value = now;
  }
}
