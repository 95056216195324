import Rails from "@rails/ujs";
import { Controller } from "stimulus";

export default class FilterableList extends Controller {
  static targets = ["submitOnClick", "withUpdateLink"];

  connect() {
    this.url = this.element.getAttribute("action");
    this.listHolderEl = document.getElementById(this.data.get("holder"));

    this.timer = null;
    this.element.addEventListener("qoteit:search:resubmit", this.onSubmit);
    this.element.addEventListener(
      "qoteit:search:refresh-listeners",
      this.onRefreshListeners
    );
    this.onRefreshListeners();
  }

  onRefreshListeners = () => {
    this.removeListeners();

    this.inputs = Array.prototype.slice
      .call(this.element.elements)
      .filter((x) => ["search", "text", "checkbox"].indexOf(x.type) >= 0)
      .filter((x) => !x.hasAttribute("data-no-auto-search"));

    this.inputs.forEach((x) => {
      x.addEventListener("input", this.onSubmit);
    });

    this.submitOnClickTargets.forEach((x) => {
      x.addEventListener("click", this.onSubmit);
    });
  };

  disconnect() {
    this.removeListeners();
  }

  removeListeners = () => {
    if (this.inputs)
      this.inputs.forEach((x) => x.removeEventListener("input", this.onSubmit));

    this.submitOnClickTargets.forEach((x) =>
      x.removeEventListener("click", this.onSubmit)
    );
  };

  // methods: private .........................................................

  onSubmit = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.callApi, 500);
    $(".alert").alert("close");
  };

  updateLinkHref = (formSerialized) => {
    this.withUpdateLinkTargets.forEach((link) => {
      const params = new URLSearchParams(formSerialized).toString();
      link.href = `${link.href.split("?")[0]}?${params.toString()}`;
    });
  };

  callApi = () => {
    $(this.listHolderEl).fadeTo(250, 0.5);

    let formSerialized = Rails.serializeElement(this.element);
    const formSerializedParams = new URLSearchParams(formSerialized);
    formSerializedParams.forEach((v, k) => {
      if (v === "") formSerializedParams.delete(k);
    });
    formSerialized = formSerializedParams.toString();

    const formData = `${formSerialized.split("?")[0]}&partial=true`;
    const newUrl = `${this.url.split("?")[0]}?${formSerialized}`;

    this.updateLinkHref(formSerialized);

    Rails.ajax({
      type: "GET",
      url: this.url,
      data: formData,
      dataType: "script",
      complete: () => {
        $(this.listHolderEl).fadeTo(250, 1);
      },
      success: () => {
        // Change url so if user reload a page - search results are saved
        window.history.replaceState({ page: newUrl }, document.title, newUrl);
      },
    });
  };
}
