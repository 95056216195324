import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["field", "suggest"];

  connect() {
    this.suggestTargets.forEach((x) => {
      x.addEventListener("click", this.onSuggest);
    });
  }

  disconnect() {
    this.suggestTargets.forEach((x) =>
      x.removeEventListener("click", this.onSuggest)
    );
  }

  // events ...................................................................

  onSuggest = (e) => {
    e.preventDefault();
    const { color } = e.target.dataset;
    this.fieldTarget.value = color;
    this.fieldTarget.select();
  };
}
