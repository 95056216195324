import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "definedCount"];

  static values = {
    multiple: Boolean,
    defined: Number,
  };

  connect() {
    this.initDropdownAnimations();
  }

  onCheck = (e) => {
    e.stopPropagation();

    if (this.multipleValue) {
      this.setActive(e.target, e.target.checked);
      this.refreshDefined();
      return;
    }

    this.checkboxTargets.forEach((x) => {
      const checked = x === e.target;
      x.checked = checked;
      this.setActive(x, checked);
    });
    this.refreshDefined();
  };

  onClear = (e) => {
    e.stopPropagation();

    this.checkboxTargets.forEach((x) => {
      x.checked = false;
      this.setActive(x, false);
    });

    this.refreshDefined();

    this.element.dispatchEvent(
      new Event("qoteit:search:resubmit", { bubbles: true })
    );
  };

  // private ..................................................................

  refreshDefined = () => {
    const count = this.checkboxTargets.filter((x) => x.checked).length;
    this.definedValue = count;
    if (count) this.definedCountTarget.innerHTML = `(${count})`;
  };

  setActive = (chk, active) => {
    chk.closest("li").classList.toggle("active", active);
  };

  initDropdownAnimations = () => {
    // Add slideDown animation to Bootstrap dropdown when expanding.
    $(this.element).on("show.bs.dropdown", () => {
      $(this.element)
        .find(".dropdown-menu")
        .first()
        .stop(true, true)
        .slideDown(250);
    });

    // Add slideUp animation to Bootstrap dropdown when collapsing.
    $(this.element).on("hide.bs.dropdown", () => {
      $(this.element)
        .find(".dropdown-menu")
        .first()
        .stop(true, true)
        .slideUp(100);
    });
  };
}
