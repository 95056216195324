import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static values = {
    vendorId: Number,
    refreshUrl: String,
  };

  connect() {
    this.initPaddle();

    window.addEventListener("event_occurred", this.onEventOccurred);
  }

  disconnect() {
    window.removeEventListener("event_occurred", this.onEventOccurred);
  }

  initPaddle() {
    window.Paddle.Setup({
      vendor: this.vendorIdValue,
      eventCallback: this.onPaddleEvent,
    });
  }

  // events ...................................................................

  onPaddleEvent = (data) => {
    document.body.dispatchEvent(
      new CustomEvent("PaddleCallbackResult", {
        bubbles: true,
        detail: data,
      })
    );
  };

  onEventOccurred = (e) => {
    const { event } = e.detail;

    if (!event || !event.startsWith("Subscriptions::")) return;

    window.location.href = this.refreshUrlValue;
  };
}
