import ApplicationController from "application_controller";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "form",
    "table",
    "action",
    "actionsContainer",
    "columns",
    // selection
    "chkOverall",
    "selected",
    "selectedCount",
    "prompt",
    "selectAll",
  ];

  reset() {
    this.disconnect();
    this.connect();
  }

  connect() {
    // -- initialization

    this.uiContainer = this.element;
    this.uiSelectAcross = null;

    this.multiSelect = this.parseBool(this.data.get("multiSelect"));
    this.checkedIds = [];
    this.totalCount = parseInt(this.data.get("totalCount"), 10);
    this.pageCount = this.selectedTargets.length;
    this.names = this.data.get("names").split(",");

    this.initActionEvents();

    // -- first render

    this.selectedTargets.forEach((el) => {
      this.updateCheckboxStatus(el);
    });

    this.updateUi();
  }

  initActionEvents() {
    this.actionTargets.forEach((x) => {
      x.addEventListener("click", this.setAction);
    });
  }

  // events ...................................................................

  onCheckChange = (e) => {
    this.setSelectAcross(false);
    this.updateCheckboxStatus(e.target);
    this.updateUi();
  };

  onTogglePage = () => {
    this.setSelectPage(this.allStatus() < 2);
  };

  onSelectAll = (e) => {
    e.preventDefault();

    this.setSelectPage(true, true);
  };

  // methods - private ........................................................

  setSelectPage(selected, selectAcross = false) {
    this.checkedIds = [];
    this.selectedTargets.forEach((x) => {
      x.checked = selected;
      this.updateCheckboxStatus(x);
    });

    this.setSelectAcross(selectAcross);
    this.updateUi();
  }

  setAction = (e) => {
    const actionName = e.target.getAttribute("data-action");
    const actionUrl = e.target.getAttribute("data-path");

    if (actionUrl) this.formTarget.action = actionUrl;

    if (!this.uiHiddenAction) {
      this.uiHiddenAction = document.createElement("input");
      this.uiHiddenAction.type = "hidden";
      this.uiHiddenAction.name = "_action";
      this.uiHiddenAction.value = actionName;
      this.tableTarget.insertAdjacentElement("afterbegin", this.uiHiddenAction);
    } else this.uiHiddenAction.value = actionName;
  };

  setSelectAcross(active) {
    if (!this.uiSelectAcross) {
      this.uiSelectAcross = document.createElement("input");
      this.uiSelectAcross.type = "hidden";
      this.uiSelectAcross.name = "_action_select_across";
      this.uiSelectAcross.value = "0";
      this.tableTarget.insertAdjacentElement("afterbegin", this.uiSelectAcross);
    }

    if (
      (active && this.uiSelectAcross.value === "1") ||
      (!active && this.uiSelectAcross.value === "0")
    )
      return; // no change

    this.uiSelectAcross.value = active ? "1" : "0";
  }

  updateCheckboxStatus(chkEl) {
    if (chkEl.checked) this.checkedIds.push(chkEl.value);
    else this.checkedIds = this.checkedIds.filter((x) => x !== chkEl.value);

    chkEl.closest(".table-row").classList.toggle("selected", chkEl.checked);
  }

  updateActions(count) {
    this.actionTargets.forEach((el) => {
      // -- update enabled status

      let disabled = true;
      switch (el.getAttribute("data-enabled")) {
        case "one-to-many":
          disabled = count < 1;
          break;
        default:
      }

      el.style.display = disabled ? "none" : "";
      if (disabled) return;

      // -- set confirmation data

      if (el.hasAttribute("data-confirm")) {
        const action = el.getAttribute("data-action");
        const actionTitle =
          el.getAttribute("data-action-label") ||
          I18n.t(`table.action_titles.${action}`);
        const actionIcon = el.getAttribute("data-icon");
        const title = `<i class="fa fa-${actionIcon}"></i> ${actionTitle} ${count} ${
          count > 1 ? this.names[1] : this.names[0]
        }${I18n.t("common.qmark")}`;
        const verify = count.toString();
        const verifyText = I18n.t("table.verify_text", {
          name: this.names[1],
          action: actionTitle.toLowerCase(),
        });
        const confirm = I18n.t("table.verify_confirm", {
          action: actionTitle.toLowerCase(),
          count,
          name: count > 1 ? this.names[1] : this.names[0],
        });

        el.setAttribute("data-title", title);
        el.setAttribute("data-confirm", confirm);
        el.setAttribute("data-commit", actionTitle);
        el.setAttribute("data-verify", verify);
        el.setAttribute("data-verify-text", verifyText);
      }
    });
  }

  allStatus() {
    const count = this.checkedIds.length;
    let rv = 0;
    if (count === this.totalCount || this.isSelectAcross) rv = 3;
    else if (count >= this.pageCount) rv = 2;
    else if (count > 0) rv = 1;
    return rv;
  }

  get isSelectAcross() {
    return this.uiSelectAcross && this.uiSelectAcross.value === "1";
  }

  updateUi() {
    if (!this.multiSelect) return;

    const count = this.checkedIds.length;
    let realCount = count;

    // overall count

    const status = this.allStatus();

    this.chkOverallTarget.indeterminate = false;
    this.chkOverallTarget.checked = false;

    switch (status) {
      case 1:
        this.chkOverallTarget.indeterminate = true;
        break;
      case 2:
        this.chkOverallTarget.checked = true;
        break;
      case 3:
        this.chkOverallTarget.checked = true;
        realCount = this.totalCount;
        break;
      default:
    }

    // selectedCount

    const allSelected = this.isSelectAcross;
    const selectedCountMsg = allSelected
      ? I18n.t("table.selected.all", {
          count: this.totalCount,
          name: this.names[1],
        })
      : I18n.t("table.selected.other", { count });
    this.selectedCountTarget.innerHTML = selectedCountMsg;

    // selectAll Prompt

    const showPrompt = status === 2 && !allSelected;
    this.promptTarget.classList.toggle("d-none", !showPrompt);
    if (showPrompt)
      this.selectAllTarget.innerHTML = I18n.t("table.selected_all", {
        count: this.totalCount,
        name: this.names[1],
      });

    // actions

    const showActions = realCount > 0;

    if (showActions) this.updateActions(realCount);

    this.actionsContainerTarget.classList.toggle("d-none", !showActions);
    this.columnsTarget.classList.toggle("d-none", showActions);
  }
}
