import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    parentEl: String,
  };

  initialize() {
    this._images_upload_url = window.App.config.images_upload_url;
  }

  connect() {
    this._selector = this.data.get("selector") || `#${this.element.id}`;

    let lang = window.App.config.locale;
    if (lang === "fr") lang = "fr_FR";
    this._direction = window.App.config.locale_rtl ? "rtl" : "ltr";

    const options = {
      selector: this._selector,
      language: lang,
      directionality: this._direction,
      images_upload_url: this._images_upload_url,
      init_instance_callback: this.onInit,
      ...this.tinyOptions,
    };

    window.TinyMCERails.initialize(this.data.get("kind") || "", options);
  }

  disconnect() {
    if (this._selector) window.tinymce.remove(this._selector);
  }

  // events ...................................................................

  onInit = (editor) => {
    this.editor = editor;
    if (this.parentElCtrl?.onEditorInit) this.parentElCtrl.onEditorInit(this);

    let el = document.querySelector(this._selector);

    if (this.stripHtml(el.value).replace(/\s/g, "").trim().length > 0) return;

    // [BUG268] needed to restore focus done in the background by tinymce
    // when calling `execCommand` or `fire`
    window.tinymceFocusStack = window.tinymceFocusStack || [];
    window.tinymceFocusStack.push(document.activeElement);

    editor.execCommand(
      this._direction === "rtl" ? "mceDirectionRTL" : "mceDirectionLTR"
    );

    setTimeout(() => {
      editor.fire("contexttoolbar-hide");
    }, 100);

    el = window.tinymceFocusStack.pop();
    if (el) el.focus();
  };

  stripHtml = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  // methods - private ........................................................

  get parentElCtrl() {
    if (!this.hasParentElValue) return null;
    if (this._parentElCtrl) return this._parentElCtrl;

    const parentEl = document.getElementById(this.parentElValue);
    if (!parentEl) {
      console.error(
        "tiny::parentElCtrl >> parentEl::not_found",
        this.parentElValue
      );
      return null;
    }
    if (!parentEl.controller) {
      console.error("tiny::parentElCtrl >> no_controller");
      return null;
    }

    this._parentElCtrl = parentEl.controller;

    return this._parentElCtrl;
  }

  get tinyOptions() {
    const rv = {};

    if (this.data.has("inline")) rv.inline = true;

    if (this.data.has("toolbarMode"))
      rv.toolbar_mode = this.data.get("toolbarMode");

    if (this.data.has("noImage")) rv.images_upload_url = null;
    if (this.data.has("noImage")) rv.images_upload_url = null;
    if (this.data.has("minHeight")) rv.min_height = this.data.get("minHeight");
    if (this.data.has("mentionUrl"))
      rv.mention_url = this.data.get("mentionUrl");

    return rv;
  }
}
