/* eslint-disable */
import ApplicationController from "application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  connect() {
    this.init();

    this.element.addEventListener("PaddleCallbackResult", this.onPaddleResult);
  }

  init() {
    this.initTheme();
    this.initLocales();
    this.select2Mount();
    // this._toastMount();
  }

  disconnect() {
    this.select2UnMount();
    // this._toastUnMount();
  }

  onPaddleResult = ({ detail }) => {
    // console.info("onPaddleResult", detail.event, detail.eventData);
  };

  // methods - private --------------------------------------------------------

  select2Mount() {
    this.select2 = $(".select2").select2({
      width: "resolve",
      dropdownAutoWidth: true,
    });
  }

  select2UnMount() {
    this.select2.select2("destroy");
  }

  initTheme() {
    this.initMainIconMenu();
    this.initTopbarMenu();
    this.initNavbarMenu();
    Waves.init();
  }

  initLocales() {
    let locale = window.App.config.locale || "en";
    $.timeago.settings.strings = jQuery.timeago.translations[locale];
  }

  // theme -----------------------------------------------------------------{{{

  initMainIconMenu() {
    const pageUrl = window.location.href.split(/[?#]/)[0];
    $(".navigation-menu a").each(function () {
      if (this.href === pageUrl) {
        $(this).parent().addClass("active"); // add active to li of the current link
        $(this).parent().parent().parent().parent().addClass("show");
        $(this).parent().parent().addClass("show");
        $(this).parent().parent().parent().addClass("active"); // add active class to an anchor
        $(this).parent().parent().parent().parent().addClass("active");
        $(this).parent().parent().parent().parent().parent().addClass("in");
        $(this).parent().parent().parent().parent().parent().addClass("active"); // add active class to an anchor
      }
    });
  }

  initTopbarMenu() {
    $(".navbar-toggle").on("click", function (event) {
      $(this).toggleClass("open");
      $("#navigation").toggleClass("open");
    });

    $(".navigation-menu>li").slice(-2).addClass("last-elements");

    $('.navigation-menu li.has-submenu a[href="#"]').on("click", function (e) {
      if ($(window).width() < 992) {
        e.preventDefault();
        $(this)
          .parent("li")
          .toggleClass("open")
          .find(".submenu:first")
          .toggleClass("open");
      }
    });
  }

  initNavbarMenu() {
    $(".has-submenu").on("click", function (e) {
      e.preventDefault();
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      $(".submenu-tab").addClass("show");
      const targ = $(this).attr("href");
      $(targ).addClass("active");
      $(targ).siblings().removeClass("active");
    });
    $(".submenu li").on("click", function (e) {
      // e.preventDefault();
      $(this).addClass("active");
      $(this).siblings().removeClass("active");
      window.location.href = e.target.href;
    });
  }

  // theme -----------------------------------------------------------------}}}
}
