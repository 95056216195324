window.formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    let decimCount = Math.abs(decimalCount);
    decimCount = Number.isNaN(decimCount) ? 2 : decimCount;

    const negativeSign = amount < 0 ? "-" : "";
    const amountVal = Math.abs(Number(amount) || 0);

    const i = parseInt(amountVal.toFixed(decimCount), 10).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
      (decimCount
        ? decimal +
          Math.abs(amountVal - i)
            .toFixed(decimCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.warn(e);
    return "---";
  }
};
