import ApplicationController from "application_controller";
import Rails from "@rails/ujs";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "form",
    "panEditor",
    "panPreview",
    "panCoverOptions",
    // Content
    "btnShowContent",
    "tabThemeContent",
    "content",
    // ContentSettings
    "btnShowContentSettings",
    "tabThemeContentSettings",
    "contentSettings",
    // Cover
    "btnShowCover",
    "tabThemeCoverPage",
    "coverContent",
  ];

  static values = {
    template: String,
  };

  initialize() {
    this._previewing = false;
    this._activeEditorIndex = null;
    this._previewUrl = this.data.get("previewUrl");
    this._coverData = null;
  }

  connect() {
    this._isTemplate = this.hasTemplateValue;
    this._isTemplateCover =
      this.hasTemplateValue && this.templateValue.toLowerCase() === "cover";
    this._isTemplateContent = this.hasTemplateValue && !this._isTemplateCover;

    this.EDITOR_IDX_CONTENT = 0;
    this.EDITOR_IDX_COVER_CONTENT = 1;

    this.element.addEventListener(
      "qote:theme:cover:changed",
      this.onCoverImageChange
    );

    if (this._isTemplateCover) {
      this.EDITOR_IDX_COVER_CONTENT = 0;
      this.EDITOR_IDX_CONTENT = 1;
      this.setActiveTab(this.btnShowCoverTarget);
    }
  }

  // events ...................................................................

  onTogglePreview = (e) => {
    const { checked } = e.target;

    this.panPreviewTarget.innerHTML = "";

    this.panEditorTarget.classList.toggle("d-none", checked);
    this.panPreviewTarget.classList.toggle("d-none", !checked);

    this.previewing = checked;

    if (checked) this.callPreview();
  };

  onPreviewLoaded = (html) => {
    this.panPreviewTarget.innerHTML = "";
    this.panPreviewTarget.appendChild(
      html.querySelector(".themes_form__preview")
    );
  };

  onToggleHasCover = (e) => {
    const { checked } = e.target;

    this.btnShowCoverTarget.classList.remove("d-none");
    this.highlightToggle(this.btnShowCoverTarget, checked);

    this.setActiveTab(
      checked ? this.btnShowCoverTarget : this.btnShowContentTarget
    );
  };

  onShowContent = (e) => this.refreshActiveTab(e);

  onShowContentSettings = (e) => this.refreshActiveTab(e);

  onShowCover = (e) => this.refreshActiveTab(e);

  onCoverImageChange = ({ detail }) => {
    this._coverData = detail;
    this.tryLoadCover();
  };

  // methods: ui update .......................................................

  refreshActiveTab = (e) => {
    e.preventDefault();
    this.setActiveTab(e.target);
  };

  setActiveTab = (tabButton) => {
    this.tabs.forEach((x) => {
      if (tabButton === x.button) {
        x.button.classList.add("active");
        x.content.classList.remove("d-none");

        if (x.editorIdx != null) {
          this.activeEditor = x.editorIdx;
          if (x.content) {
            const editor = x.content.querySelector(".tox-tinymce");
            if (editor) editor.style.height = "34cm";
          }
        } else this.activeEditor = null;
      } else {
        x.button.classList.remove("active");
        x.content.classList.add("d-none");
      }
    });

    // cover options ?
    if (tabButton === this.btnShowCoverTarget && !this.previewing) {
      this.panCoverOptionsTarget.classList.remove("d-none");
    } else {
      this.panCoverOptionsTarget.classList.add("d-none");
    }

    // preview ?
    if (this.previewing) this.callPreview();
  };

  tryLoadCover = () => {
    if (!this._tryLoadCover) this._tryLoadCover = 1;

    if (this._tryLoadCover > 15) {
      // retry for 30sec. max
      this._tryLoadCover = 1;
      console.warn("tryLoadCover: tinymce is not ready after 5 retries.");
      return;
    }

    if (!this.coverEditor || !this.coverEditor.getDoc()) {
      this._tryLoadCover += 1;
      if (this._tryLoadCover > 3)
        console.warn("tryLoadCover: retrying in 2 secs...", this._tryLoadCover);
      setTimeout(this.tryLoadCover, 2000);
      return;
    }

    this._tryLoadCover = 1;
    this.loadCover();
  };

  loadCover = () => {
    const detail = this._coverData;
    const body = this.coverEditor.getDoc().querySelector("body");

    if (!detail.url) {
      body.style.background = detail.color;
      return;
    }

    if (!detail.position || detail.position === "cover") {
      body.style.background = `url(${detail.url}) no-repeat center center`;
      body.style.backgroundColor = detail.color;
      body.style.backgroundSize = "21cm 29.7cm";
      return;
    }

    body.style.background = `url(${detail.url})`;
    body.style.backgroundColor = detail.color;
    body.style.backgroundPosition = detail.position;
    body.style.backgroundRepeat = detail.repeat || "no-repeat";

    if (detail.size) body.style.backgroundSize = `${detail.size}%`;
  };

  callPreview = () => {
    if (this.tabFieldName === null) {
      console.warn("callPreview:tabFieldName_nil");
      return;
    }

    this.uiLoading();

    const content = this.activeEditor.getContent();
    const formData = new FormData();
    formData.append("content", content);
    formData.append("field_name", this.tabFieldName);

    if (this.isCoverEditor && this._coverData) {
      formData.append("cover_options[url]", this._coverData.url);
      formData.append("cover_options[blob_id]", this._coverData.blob_id);
      formData.append("cover_options[position]", this._coverData.position);
      formData.append("cover_options[repeat]", this._coverData.repeat);
      formData.append("cover_options[size]", this._coverData.size);
      formData.append("cover_options[color]", this._coverData.color);
    } else {
      // # Add theme content settings to params
      const formSerialized = Rails.serializeElement(this.formTarget);
      const formParams = new URLSearchParams(formSerialized);
      formParams.forEach((v, k) => {
        if (k.startsWith("theme[content_")) formData.append(k, v);
        if (k === "theme[_destroy_watermark]") formData.append(k, v);
        if (k === "theme[existing_id]") formData.append(k, v);

        if (k === "theme[watermark]") {
          const { files } = document.getElementsByName("theme[watermark]")[0];
          if (files && files[0]) formData.append(k, files[0]);
        } else if (k.startsWith("theme[watermark")) formData.append(k, v);
      });
    }

    Rails.ajax({
      type: "POST",
      url: this._previewUrl,
      data: formData,
      error: (e) => {
        console.warn("themes::callPreview -- error", { e });
        this.uiLoading(I18n.t("error_while_previewing"));
      },
      success: (html) => {
        this.onPreviewLoaded(html);
      },
    });
  };

  uiLoading = (error) => {
    this.panPreviewTarget.innerHTML = `
    <div class="card-body d-flex justify-content-center">
    ${
      error ||
      `<div class="spinner-border" role="status"><span class="sr-only">${I18n.t(
        "loading"
      )}</span></div>`
    }
    </div>
`;
  };

  // methods: private .........................................................

  get activeEditor() {
    if (this._activeEditorIndex == null)
      this._activeEditorIndex = this.EDITOR_IDX_CONTENT;
    return window.tinymce.get(this._activeEditorIndex);
  }

  set activeEditor(idx) {
    this._activeEditorIndex = idx;
    if (idx !== null) window.tinymce.setActive(window.tinymce.get(idx));
  }

  get coverEditor() {
    return window.tinymce.get(this.EDITOR_IDX_COVER_CONTENT);
  }

  get isCoverEditor() {
    return this._activeEditorIndex === this.EDITOR_IDX_COVER_CONTENT;
  }

  get previewing() {
    return this._previewing;
  }

  get tabs() {
    if (this._tabs) return this._tabs;
    this._tabs = [
      {
        button: this.btnShowContentTarget,
        content: this.tabThemeContentTarget,
        editorIdx: this.EDITOR_IDX_CONTENT,
        editorFieldName: "content",
      },
      {
        button: this.btnShowContentSettingsTarget,
        content: this.tabThemeContentSettingsTarget,
        editorIdx: null,
        editorFieldName: "content",
      },
      {
        button: this.btnShowCoverTarget,
        content: this.tabThemeCoverPageTarget,
        editorIdx: this.EDITOR_IDX_COVER_CONTENT,
        editorFieldName: "cover_content",
      },
    ];

    return this._tabs;
  }

  get tabFieldName() {
    const rv = this.tabs.filter(
      (x) => x.editorIdx === this._activeEditorIndex
    )[0];

    return rv ? rv.editorFieldName : null;
  }

  set previewing(value) {
    this._previewing = value;

    if (this._previewing) {
      this.panCoverOptionsTarget.classList.add("d-none");
    } else {
      this.panCoverOptionsTarget.classList.toggle(
        "d-none",
        this._activeEditorIndex !== this.EDITOR_IDX_COVER_CONTENT
      );
    }
  }
}
