import { Controller } from "stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  static values = {
    type: String,
    options: Object,
  };

  connect() {
    const element = this.hasChartTarget ? this.chartTarget : this.element;

    if (!this.optionsValue) {
      console.warn("[chart] options is required");
      return;
    }

    this._chart = new ApexCharts(element, this.optionsValue);
    this._chart.render();
  }

  disconnect() {
    if (this._chart) this._chart.destroy();
  }

  // events ...................................................................

  // render ...................................................................

  // methods - private ........................................................
}
