import ApplicationController from "application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  static targets = [
    "price",
    "quantity",
    "discount",
    "discountValue",
    "discountPercentWrapper",
    "total",
    "title",
    "description",
    "expandedIndicator",
  ];

  initialize() {
    this._debouncedRecompute = debounced.debounce(this.recompute, {
      wait: 200,
    });
  }

  connect() {
    this._hasDescription =
      this.hasDescriptionTarget &&
      !this.isBlank(this.descriptionTarget.innerText);
    this._expanded = false;

    this.onUpdate();
    this.priceTarget.addEventListener("input", this.onUpdate);
    this.quantityTarget.addEventListener("input", this.onUpdate);

    if (this.hasDiscountValueTarget) {
      this.discountValueTarget.addEventListener("input", this.onUpdate);
      this.discountPercentWrapperTarget.addEventListener(
        "click",
        this.onUpdate
      );
    }

    if (this._hasDescription) {
      this.titleTarget.addEventListener("click", this.onToggleDescription);
      this.titleTarget.classList.add("q-expandable");
    } else if (this.hasExpandedIndicatorTarget)
      this.expandedIndicatorTarget.classList.add("d-none");
  }

  disconnect() {
    this.priceTarget.removeEventListener("input", this.onUpdate);
    this.quantityTarget.removeEventListener("input", this.onUpdate);
    if (this.hasDescription)
      this.titleTarget.removeEventListener("click", this.onToggleDescription);

    if (this.hasDiscountValueTarget) {
      this.discountValueTarget.removeEventListener("input", this.onUpdate);
      this.discountPercentWrapperTarget.removeEventListener(
        "click",
        this.onUpdate
      );
    }
  }

  onToggleDescription = () => {
    this._expanded = !this._expanded;
    this.descriptionTarget.classList.toggle("d-none", !this._expanded);
    this.expandedIndicatorTarget.classList.toggle(
      "fa-rotate-180",
      this._expanded
    );
  };

  onUpdate = () => {
    this._debouncedRecompute();
  };

  get discountPercentEls() {
    if (this._discountPercentEl) return this._discountPercentEl;

    this._discountPercentEl = Array.from(
      this.discountPercentWrapperTarget.querySelectorAll("input[type=radio]")
    );
    return this._discountPercentEl;
  }

  getDiscount = (price) => {
    let discount = 0;
    if (this.hasDiscountTarget)
      discount = parseFloat(this.discountTarget.value || "0");
    else if (this.hasDiscountValueTarget) {
      const percent =
        this.discountPercentEls.filter((x) => x.checked)[0].value === "true";
      discount = parseFloat(this.discountValueTarget.value || "0");
      discount = percent ? (price * discount) / 100 : discount;
    }

    return discount;
  };

  recompute = () => {
    const price = parseFloat(this.priceTarget.value || "0");
    const quantity = parseFloat(this.quantityTarget.value || "0");
    let total = price * quantity;
    total -= this.getDiscount(total);

    this.totalTarget.dataset.value = total * 100;
    this.totalTarget.textContent = this.formatMoney(total);

    this.highlightText(this.totalTarget, () => {
      this.totalTarget.dispatchEvent(
        new Event("total:changed", {
          bubbles: true,
        })
      );
    });
  };
}
