import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._guessTimeZone();
  }

  // methods - private --------------------------------------------------------

  _guessTimeZone() {
    this.element.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
