import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["date"];

  // events -------------------------------------------------------------------

  autoFillDate() {
    const now = new Date().toISOString().substring(0, 10);
    this.dateTarget.value = now;
  }
}
