import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("ajax:before", this.onBefore);
    this.element.addEventListener("ajax:complete", this.onComplete);
  }

  disconnect() {}

  onBefore = () => {
    this.element.disabled = true;
    this.element.classList.toggle("disable", true);
    this.element.classList.toggle("loading", true);

    this.element.appendChild(this.loadingEl);
  };

  onComplete = () => {
    this.element.disabled = false;
    this.element.classList.toggle("disable", false);
    this.element.classList.toggle("loading", false);

    this.element.removeChild(this.loadingEl);
  };

  get loadingEl() {
    if (this._loadingEl) return this._loadingEl;

    this._loadingEl = document.createElement("i");
    this._loadingEl.className = "fa fa-spinner fa-spin ml-1";
    return this._loadingEl;
  }
}
