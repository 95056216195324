import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const wrapper = this.element.parentNode.parentNode;
    this._items = wrapper.querySelectorAll(".quick-filter-item");
    this.element.addEventListener("input", this.onChange);
    this.element.focus();
  }

  disconnect() {
    this.element.removeEventListener("input", this.onClick);
  }

  // events ...................................................................

  onChange = (e) => {
    e.stopPropagation();

    const term = this.element.value.toLowerCase();

    this._items.forEach((x) => {
      x.classList.toggle(
        "d-none",
        !(x.attributes["data-text"].value || "").includes(term)
      );
    });
  };

  // private ..................................................................
}
