import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._submitter = document.getElementById(this.data.get("submitter"));

    this._submitter.addEventListener("ajax:before", this.onBefore);
    this._submitter.addEventListener("ajax:complete", this.onComplete);
  }

  disconnect() {
    this._submitter.removeEventListener("ajax:before", this.onBefore);
    this._submitter.removeEventListener("ajax:complete", this.onComplete);
  }

  // events ...................................................................

  onChange = (e) => {
    const { checked } = e.target;
    this._submitter.dataset.params = `no_redirect=true&${e.target.name}=${checked}`;
    this._submitter.click();
  };

  onBefore = () => {
    this.uiLoading(true);
  };

  onComplete = () => {
    this.uiLoading(false);
  };

  uiLoading = (loading) => {
    this.element.disabled = loading;
  };
}
