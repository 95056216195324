import Carousel from "stimulus-carousel";

export default class extends Carousel {
  static targets = ["field"];

  static values = {
    initial: Number,
  };

  connect() {
    super.connect();

    this.swiper.on("slideChange", this.onSlideChange);
    this.swiper.on("click", this.onSlideClick);
    this.refreshSelectedSlide();
  }

  get defaultOptions() {
    const initialSlide = this.hasInitialValue
      ? this.getSlideById(this.initialValue)
      : 0;

    return {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 10,
      grabCursor: true,
      initialSlide,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    };
  }

  // events ...................................................................

  onSlideClick = (swiper) => {
    swiper.slideTo(swiper.clickedIndex);
  };

  onSlideChange = () => {
    this.refreshSelectedSlide();
  };

  // ui .......................................................................

  refreshSelectedSlide = () => {
    this._selectedSlide = this.swiper.slides[this.swiper.realIndex];
    if (!this._selectedSlide) return;

    const { id } = this._selectedSlide.dataset;

    if (this.hasFieldTarget) {
      this.fieldTarget.value = id;
    }
  };

  // helpers ..................................................................

  getSlideById = (id) => {
    const idS = id.toString();
    let rv = -1;

    const list = this.element.querySelectorAll(".swiper-slide");

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].dataset.id === idS) {
        rv = i;
        break;
      }
    }

    return rv;
  };
}
