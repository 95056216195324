import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["panCommentNew"];

  static values = {
    userId: String,
    userName: String,
  };

  initialize() {
    this.bindElementController();
  }

  disconnect() {}

  // events ..................................................................

  onToggleNewComment = () => {
    const show = this.panCommentNewTarget.classList.contains("d-none");
    this.panCommentNewTarget.classList.toggle("d-none", !show);

    if (show) this.newNoteCtrl.onOpen();
    else this.newNoteCtrl.onCancel();
  };

  onReplyToComment = () => {
    const parentNote = this.element.parentElement.closest(
      "[data-controller=notes--single]"
    );

    if (parentNote.controller)
      parentNote.controller.onCallEditor((ed) => {
        ed.insertMention({
          id: this.userIdValue,
          text: this.userNameValue,
        });

        ed.insertContent(" ");
        ed.focus();
      });
  };

  onCallEditor = (fn) => {
    this.newNoteCtrl.onFocusAndEdit(fn);
  };

  // methods: private ..........................................................

  get editor() {
    return this.newNoteCtrl.editor;
  }

  get newNoteCtrl() {
    if (this._newNoteCtrl) return this._newNoteCtrl;
    this._newNoteCtrl = this.panCommentNewTarget.querySelector(
      "form[data-controller=notes--new]"
    ).controller;
    return this._newNoteCtrl;
  }
}
