// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import { Autocomplete } from "stimulus-autocomplete";
import ContentLoader from "stimulus-content-loader";
import Popover from "stimulus-popover";
import Carousel from "stimulus-carousel";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.js$/);
const contextComponents = require.context(
  "../../components",
  true,
  /_controller.js$/
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);

application.register("autocomplete", Autocomplete);
application.register("content-loader", ContentLoader);
application.register("popover", Popover);
application.register("carousel", Carousel);
