import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    multiple: Boolean,
  };

  connect() {
    this.element.addEventListener("click", this.onClick);
    this.setActive(this.element.querySelector("[type=checkbox]").checked);
  }

  disconnect() {
    this.element.removeEventListener("click", this.onClick);
  }

  // events ...................................................................

  onChange = (e) => {
    e.stopPropagation();
    this.setActive(e.target.checked || !this.multipleValue);
  };

  onClick = (e) => {
    e.stopPropagation();
  };

  // private ..................................................................

  setActive = (active) => {
    this.element.classList.toggle("active", active);
  };
}
