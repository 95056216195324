import CableReady from "cable_ready";

import consumer from "./consumer";

function refreshActiveState(active) {
  const $icons = $("#notifications-icon").find(".nav-link>i.fa");

  if (active) $icons.removeClass("text-light");
  else $icons.addClass("text-light");
}

refreshActiveState(false);

consumer.subscriptions.create("UserChannel", {
  connected() {
    refreshActiveState(true);
  },

  disconnected() {
    refreshActiveState(false);
  },

  received(data) {
    if (data.cableReady) CableReady.perform(data.operations);
  },
});
