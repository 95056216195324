import ApplicationController from "application_controller";
import Rails from "@rails/ujs";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "panPreview",
    "selTheme",
    "btnExportPdf",
    "btnShareByEmail",
  ];

  initialize() {
    this._baseUrl = this.data.get("url");
    this._previousTheme = null;
  }

  connect() {
    if (this.isTurbolinksPreview) return;

    $(this.selThemeTarget).on("select2:select", this.onThemeChange);

    this.initButtons();
    if (this.selThemeTarget.value) this.callPreview(this.selThemeTarget.value);
  }

  // events ...................................................................

  onThemeChange = (e) => {
    const { id } = e.params.data;

    this.callPreview(id);
  };

  onPreviewLoaded = (html) => {
    this.panPreviewTarget.innerHTML = "";
    this.panPreviewTarget.appendChild(
      html.querySelector(".themes_form__preview")
    );
  };

  // methods: ui update .......................................................

  callPreview = (themeId) => {
    if (!themeId || `${themeId}` === this._previousTheme) return;

    this._previousTheme = `${themeId}`;
    this.uiLoading();
    this.uiUpdateButtons(themeId);

    Rails.ajax({
      type: "GET",
      url: `${this._baseUrl}/${themeId}`,
      error: (e) => {
        console.warn("themes::callPreview -- error", { e });
        this.uiLoading(I18n.t("error_while_previewing"));
      },
      success: (html) => {
        this.onPreviewLoaded(html);
      },
    });
  };

  get buttons() {
    return [this.btnExportPdfTarget, this.btnShareByEmailTarget];
  }

  initButtons = () => {
    this.buttons.forEach((x) => {
      x.dataset.tplUrl = x.href;
    });
  };

  uiUpdateButtons = (themeId) => {
    this.buttons.forEach((x) => {
      x.href = x.dataset.tplUrl.replace("__THEME__", themeId);
      x.classList.toggle("d-none", !themeId);
    });
  };

  uiLoading = (error) => {
    this.panPreviewTarget.innerHTML = `
    <div class="card-body d-flex justify-content-center">
    ${
      error ||
      `<div class="spinner-border" role="status"><span class="sr-only">${I18n.t(
        "loading"
      )}</span></div>`
    }
    </div>
`;
  };
}
