import ApplicationController from "application_controller";
import debounced from "debounced";

export default class extends ApplicationController {
  static targets = ["field", "suggest"];

  static values = {
    url: String,
    object: Object,
  };

  initialize() {
    this._debouncedUpdate = debounced.debounce(this.onUpdate, { wait: 200 });
    this._objectId = this.objectVal;
  }

  connect() {}

  disconnect() {}

  get objectVal() {
    return this.objectValue?.id ? this.objectValue.id.toString() : "";
  }

  // events ...................................................................

  onUpdate = () => {
    const parsedUrl = this.urlValue.replace("LOADER_OBJECT_ID", this._objectId);

    const html = `
    <div data-controller="content-loader"
        data-content-loader-url-value="${parsedUrl}">
      <div class="card-body d-flex justify-content-center">
        <div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>
      </div>
    </div>
    `;

    this.element.innerHTML = html;
  };

  objectValueChanged = () => {
    const newId = this.objectVal;
    if (newId === this._objectId) return;

    this._objectId = newId;
    this._debouncedUpdate();
  };
}
