import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slug"];

  connect() {
    this._generateSlug = !this.slugTarget.value;
  }

  disconnect() {}

  // events -------------------------------------------------------------------

  onNameChange(e) {
    if (!this.slugTarget.value) this._generateSlug = true;
    if (!this._generateSlug || !e.target.value) return;

    this.slugTarget.value = this.slugify(e.target.value);
  }

  onSlugChange() {
    this._generateSlug = !this.slugTarget.value;
  }

  onSubmit() {
    this.slugTarget.value = this.slugify(this.slugTarget.value);
  }

  // methods - private --------------------------------------------------------

  slugify(str) {
    const string = str || "";
    const a =
      "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
    const b =
      "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
    const p = new RegExp(a.split("").join("|"), "g");

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w-]+/g, "") // Remove all non-word characters
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }
}
