import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this._submitter = document.getElementById(this.data.get("submitter"));

    this._submitter.addEventListener("ajax:before", this.onBefore);
    this._submitter.addEventListener("ajax:complete", this.onComplete);

    $(this.element).on("select2:select", this.onSelect);
  }

  disconnect() {
    this._submitter.removeEventListener("ajax:before", this.onBefore);
    this._submitter.removeEventListener("ajax:complete", this.onComplete);
  }

  // events ...................................................................

  onSelect = (e) => {
    const { id } = e.params.data;
    this._submitter.dataset.params = `access_level=${id}`;
    this._submitter.click();
  };

  onBefore = () => {
    this.uiLoading(true);
  };

  onComplete = () => {
    this.uiLoading(false);
  };

  uiLoading = (loading) => {
    this.element.disabled = loading;
  };
}
