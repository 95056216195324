import { Controller } from "stimulus";
import I18n from "lib/i18n";

export default class extends Controller {
  connect() {
    this._isGeneric = this.data.has("generic");
    this.select2 = $(this.element).select2(this.buildOptions());
    this.element.controller = this;
  }

  disconnect() {
    this.select2UnMount();
    this.element.controller = null;
  }

  // events ...................................................................

  // methods - private ........................................................

  buildOptions = () => {
    const url = this.data.get("url");
    const showAll = this.data.has("all") && this.data.get("all") !== "0";
    const { dropdownCssClass, selectionCssClass } = this.element.dataset;

    const options = {
      width: "100%",
      multiple: true,
      tags: true,
      createTag: this.createTag.bind(this),
      ajax: {
        url,
        dataType: "json",
        delay: 250,
        data(params) {
          const query = { q: params.term };
          if (showAll) query.all = "true";
          return query;
        },
        processResults: (data) => {
          return {
            results: data.data.map((x) => ({
              id: `id:${x.id}`,
              confirmed: x.confirmed,
              avatar_url: x.avatar_url,
              full_name: x.full_name,
            })),
          };
        },
      },
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
    };

    if (!url) delete options.ajax;

    if (dropdownCssClass) options.dropdownCssClass = dropdownCssClass;
    if (selectionCssClass) options.selectionCssClass = selectionCssClass;

    const isGeneric = this._isGeneric;
    options.language = {
      noResults() {
        return isGeneric
          ? I18n.t("select_emails.no_results.generic")
          : I18n.t("select_emails.no_results.no_generic");
      },
    };

    return options;
  };

  createTag(params) {
    const term = $.trim(params.term);

    if (!this.emailIsValid(term)) return null;

    return {
      id: `email:${term}`,
      newEmail: term,
      full_name: this._isGeneric
        ? I18n.t("select_emails.full_name.generic", { term })
        : I18n.t("select_emails.full_name.no_generic", { term }),
      newTag: true,
    };
  }

  emailIsValid(email) {
    if (!email) return false;
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  templateResult(user) {
    if (user.loading) return null;

    let markup = "<div class='select2-result-user'>";

    if (user.avatar_url)
      markup += `<img src='${user.avatar_url}' class='thumb-sm rounded-circle mr-2' />`;

    markup +=
      "<div class='select2-result-user__meta'>" +
      `<div class='select2-result-user__fullname'>${user.full_name}</div>`;

    if (!user.confirmed && !user.newTag)
      markup += `<div class='text-muted'>${I18n.t(
        "select_emails.no_recent_log_in"
      )}</div>`;

    markup += "</div></div>";

    return $(markup);
  }

  templateSelection(user) {
    return user.newTag ? user.newEmail : user.full_name || user.text;
  }

  select2UnMount() {
    this.select2.select2("destroy");
  }
}
