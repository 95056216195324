import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static values = {
    targetDivId: String,
  };

  connect() {
    this._containerEl = document.getElementById(this.targetDivIdValue);
    this._contentEl = this._containerEl.querySelector(".note-content");
  }

  get formEl() {
    return this._containerEl.querySelector(".note-form");
  }

  // events ..................................................................

  onCancel = () => {
    this._contentEl.classList.remove("d-none");
    this.formEl.innerHTML = "";
  };
}
