import Swal from "sweetalert2";
import ApplicationController from "application_controller";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "loadTermsWrapper",
    "selectTermGroup",
    "loading",
    "btnLoad",
    "wrapper",
    "template",
  ];

  connect() {
    $(this.selectTermGroupTarget).on("select2:select", this.onLoadFromGroup);
    this.btnLoadTarget.addEventListener("ajax:before", this.onAjaxStart);
    this.btnLoadTarget.addEventListener("ajax:complete", this.onAjaxEnd);
  }

  // events ...................................................................

  onLoadFromGroup = (e) => {
    const { id } = e.params.data;
    const tplUrl = this.btnLoadTarget.attributes.href.value;
    const btn = this.btnLoadTarget;

    btn.attributes.href.value = tplUrl.replace("::term_group_id::", id);
    btn.click();

    btn.attributes.href.value = tplUrl;

    // remove selected value from select

    const $select = $(this.selectTermGroupTarget);
    e.params.data.element.remove();
    $select.val(null);
    $select.trigger("change.select2");
  };

  onAjaxStart = () => {
    this.loadingTarget.classList.remove("d-none");
    this.highlight(this.loadingTarget);

    $(this.loadTermsWrapperTarget.querySelector(".select2")).velocity(
      { opacity: 0.5 },
      { duration: 200 }
    );
  };

  onAjaxEnd = () => {
    this.highlight(this.loadingTarget, () => {
      this.loadingTarget.classList.add("d-none");

      $(this.loadTermsWrapperTarget.querySelector(".select2")).velocity(
        { opacity: 1 },
        { duration: 200 }
      );

      const $select = $(this.selectTermGroupTarget);
      if ($select.find("option").length - 1 < 1) {
        const wrapper = this.loadTermsWrapperTarget;
        this.highlightRemoval(wrapper, () => {
          wrapper.remove();
        });
      }
    });

    this.recomputePositions();
  };

  recomputePositions() {
    const fields = this.wrapperTarget.querySelectorAll(".dragPositionField");

    let i = 1;
    fields.forEach((f) => {
      f.value = i.toString();
      i += 1;
    });

    i = 1;
    const rows = this.wrapperTarget.querySelectorAll(".termgroup-term");
    rows.forEach((row) => {
      this.updateRailsFieldsNames(row, `quotation[terms_attributes][`, i);
      i += 1;
    });
  }

  onAddTerm = (e) => {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.wrapperTarget.insertAdjacentHTML("beforeend", content);

    this.highlight(this.wrapperTarget.lastElementChild);
  };

  onCancel(e) {
    e.preventDefault();

    const item = e.target.closest(".termgroup-term");

    this.highlightRemoval(item, () => {
      item.remove();
    });
  }

  onRemoveTerm(e) {
    e.preventDefault();

    const swalOptions = {
      title: I18n.t("delete_confirm"),
      html: e.target.dataset.title,
      showCancelButton: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      focusCancel: true,
      customClass: {
        container: `DataConfirmModal DataConfirmModal--danger`,
        title: `card-header bg-danger text-white my-0 w-100`,
        confirmButton: `btn btn-danger waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value) return;
      const item = e.target.closest(".termgroup-term");
      const id = this._termId(item);

      this.highlightRemoval(item, () => {
        if (id) {
          // ajax remove
          item.querySelector("input[name*='_destroy']").value = 1;
          item.style.display = "none";
        } else {
          // remove from dom only
          item.parentNode.removeChild(item);
        }
      });
    });
  }

  // methods: private ..........................................................

  _termId(item) {
    return item.getAttribute("data-term-id");
  }

  newRecord(item) {
    return item.hasAttribute("data-term-new");
  }
}
