import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static values = {
    args: Object,
  };

  connect() {}

  // events ...................................................................

  onOpen = () => {
    const args = this.argsValue || {};
    args.locale = window.App.config.locale;
    window.Paddle.Checkout.open(args);
  };
}
