import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  static values = {
    options: String,
  };

  initialize() {
    this._options = null;
  }

  connect() {}

  disconnect() {}

  get selectController() {
    return this.selectTarget.controller;
  }

  // events ...................................................................

  onAddValue = (e) => {
    const { value } = e.target.dataset;

    const newOption = new Option(value.split(":")[1], value, true, true);
    $(this.selectTarget).append(newOption).trigger("change");
  };

  optionsValueChanged(newVal) {
    if (this._options == null) {
      this._options = newVal;
      return;
    }

    this._options = JSON.parse(newVal || "[]");
    if (!Array.isArray(this._options)) this._options = [];
    const options = this._options.map((x) => new Option(x.text, x.id));

    if (options.length) options[0].selected = true;

    const $el = $(this.selectTarget);
    $el.empty().val("").trigger("change");
    options.forEach((x) => $el.append(x));
    $el.trigger("change");
  }
}
