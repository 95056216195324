import { Controller } from "stimulus";
import Swal from "sweetalert2";
import I18n from "lib/i18n";

export default class extends Controller {
  static values = {
    url: String,
  };

  initialize() {
    this.element.controller = this;
  }

  connect() {
    this.optionUniqueBy = this.data.get("uniqueBy");
    this.optionFormatResult = this.data.get("formatResult");

    const options = {
      width: "resolve",
      dropdownAutoWidth: true,
      templateSelection: this.formatSelection,
      templateResult: this.formatSelection,
    };

    this.setAjaxOptions(options);

    this.select2 = $(this.element).select2(options);

    if (this.optionUniqueBy)
      this.select2.on("select2:selecting", this.onValidateUniqueBy);
  }

  disconnect() {
    if (!this.select2) return;
    if (!this.select2.hasClass("select2-hidden-accessible")) {
      this.select2 = null;
      return;
    }

    this.select2.select2("destroy");
    this.select2 = null;
  }

  setAjaxOptions = (options) => {
    if (!this.hasUrlValue) return;

    const ajax = {
      url: this.urlValue,
      delay: 250,
      data(params) {
        const query = {
          "q[search_cont]": params.term,
          page: params.page || 1,
        };
        return query;
      },
      processResults(data) {
        return {
          results: data.data,
          pagination: data.meta.pagination,
        };
      },
    };

    options.ajax = ajax;
  };

  itemData = (item) => {
    if (!item || !item.id) return null;

    if (item.element) {
      const data = item.element.dataset;
      return {
        id: item.id,
        text: item.text || item.title || item.text,
        img: item.img || data.img,
        color: item.color || data.color,
        textColor: item.textColor || data.textColor,
      };
    }

    return item;
  };

  formatSelection = (item) => {
    const data = this.itemData(item);
    if (!data) return this.element.dataset.placeholder;

    if (data.color) return this.formatWithColor(data);
    if (data.img) return this.formatWithImage(data);

    return $("<span>").text(data.text);
  };

  formatWithColor = (data) => {
    const { textColor, color, text } = data;
    return $(
      `<span class='badge color-label' style='background-color: ${color}; color: ${textColor}; margin-right: 1em;'></span>`
    ).text(text);
  };

  formatWithImage = (data) => {
    const { img, text } = data;
    return $(`<span><img src="${img}" class="select2-img">${text}</span>`);
  };

  onValidateUniqueBy = (e) => {
    if (!this.optionUniqueBy) return;

    const selected = e.params.args.data.id;
    let alreadySelected = false;

    const selectedValues = [];

    document.querySelectorAll(this.optionUniqueBy).forEach((node) => {
      if (this.element === node) return;
      if (node.value === selected) alreadySelected = true;
      selectedValues.push(node.value);
    });

    if (!alreadySelected) return;

    e.preventDefault();

    const options = {
      html: `<center style='color: #f1646c'>${I18n.t(
        "can_not_be_selected_twice"
      )}</center>`,
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    };

    Swal.fire(options);
  };
}
