import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "label", "destroy", "btnRemove"];

  static values = {
    title: String,
    titleBlank: String,
    exists: Boolean,
  };

  connect() {}

  disconnect() {}

  // events ...................................................................

  onRemove = () => {
    this.inputTarget.value = "";
    this.onFileChange();
  };

  onFileChange = () => {
    const file = this.inputTarget.files[0];

    this.destroyTarget.value = file ? "false" : "true";

    const filename = !!file && file.name;

    if (filename) {
      this.labelTarget.innerText = filename;
    } else {
      this.labelTarget.innerText = this.hasFile
        ? this.titleValue
        : this.titleBlankValue;
    }

    this.render();
  };

  // render ...................................................................

  render = () => {
    this.btnRemoveTarget.disabled = !this.hasFile;
  };

  // methods - private ........................................................

  get hasFile() {
    return (
      (!!this.inputTarget.files && this.inputTarget.files.length > 0) ||
      (this.destroyTarget.value === "false" && this.existsValue)
    );
  }
}
