import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    const options = {
      group: "shared",
      handle: ".drag-handle",
      animation: 150,
      onEnd: this.onUpdate,
      onUnchoose: this.onUnchoose,
    };

    if (this.data.has("draggable"))
      options.draggable = this.data.get("draggable");

    const el = this.data.has("el")
      ? this.element.querySelector(this.data.get("el"))
      : this.element;

    this.sortable = Sortable.create(el, options);
    this.autoSubmitSelector = this.data.get("submit");
  }

  onUnchoose = () => {
    const editors = this.element.querySelectorAll("[data-controller=tiny]");
    editors.forEach((x) => {
      window.tinymce.remove(`#${x.id}`);

      // reconnect
      const name = x.dataset.controller;
      setTimeout(() => x.setAttribute("data-controller", name), 1);
      x.setAttribute("data-controller", "");
    });
  };

  onUpdate = (evt) => {
    if (evt.oldIndex === evt.newIndex) return;

    this.recomputePositions();

    if (this.autoSubmitSelector)
      this.element.closest(this.autoSubmitSelector).submit();
  };

  recomputePositions() {
    const fields = this.element.querySelectorAll(".dragPositionField");

    let i = 1;
    fields.forEach((f) => {
      f.value = i.toString();
      i += 1;
    });
  }
}
