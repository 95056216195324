import ApplicationController from "application_controller";
import { range } from "lib/array";

import pdfjs from "pdfjs-dist/build/pdf";

export default class extends ApplicationController {
  static targets = [];

  initialize() {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  connect() {
    this._data = this.data.get("data");
    this._pdf = null;

    this.fetchPDF();
  }

  // events ...................................................................

  // render ...................................................................

  renderPages = (pages) => {
    this.element.innerHTML = "";
    let scale = window.devicePixelRatio * 1.5;
    if (pages[0]) scale = 794 / pages[0].getViewport(1.0).width;

    pages.forEach((page) => {
      const viewport = page.getViewport(scale);

      // Prepare canvas using PDF page dimensions
      const canvas = document.createElement("canvas");
      canvas.classList.add("themes_form__preview__content");
      canvas.height = 1122;
      canvas.width = 794;

      // Render PDF page into canvas context
      const canvasContext = canvas.getContext("2d");
      const renderContext = { canvasContext, viewport };

      window.requestAnimationFrame(() => {
        page.render(renderContext);
      });
      // .then(() => console.log("Page rendered"));

      this.element.appendChild(canvas);
    });
  };

  // methods - private ........................................................

  fetchPDF = () => {
    const pdfData = atob(this._data);

    this.getDocument({ data: pdfData })
      .then(this.getPages, (error) => {
        console.warn("PDF::Error", error);
      })
      .then(this.renderPages)
      .catch((error) => {
        console.warn("PDF::Error", error);
      });
  };

  getDocument = (url) => {
    return pdfjs.getDocument(url).promise;
  };

  getPages = (pdf) => {
    this._pdf = pdf;
    const pagePromises = range(1, pdf.numPages).map((number) =>
      pdf.getPage(number)
    );
    return Promise.all(pagePromises);
  };
}
