import Rails from "@rails/ujs";
import Swal from "sweetalert2";
import I18n from "lib/i18n";

export default class DataConfirmModal {
  static settings = {
    title: I18n.t("data_confirm.title"),
    commit: I18n.t("data_confirm.commit"),
    cancel: I18n.t("data_confirm.cancel"),
    elements: [
      "a[data-confirm]",
      "button[data-confirm]",
      "input[type=submit][data-confirm]",
    ],
    focus: "commit",
  };

  static getOptions(element) {
    let txt = element.getAttribute("data-confirm");
    if (txt === "true") txt = null;
    return {
      title: element.getAttribute("data-title"),
      text: txt,
      focus: element.getAttribute("data-focus"),
      method:
        element.getAttribute("data-method") || element.getAttribute("method"),
      type: element.getAttribute("data-type"),
      commit: element.getAttribute("data-commit"),
      cancel: element.getAttribute("data-cancel"),
      remote: element.getAttribute("data-remote"),
      verify: element.getAttribute("data-verify"),
      verifyLabel: element.getAttribute("data-verify-text"),
    };
  }

  static buildModal(element) {
    const options = this.getOptions(element);
    const { settings } = this;

    let { type } = options;
    if (!type) {
      if (options.method === "delete") type = "danger";
      else type = "question";
    }

    let focusElement;
    if (options.focus) {
      focusElement = options.focus;
    } else if (type === "danger") {
      focusElement = "cancel";
    } else {
      focusElement = settings.focus;
    }

    let headerStyle = "";
    let buttonStyle = "";
    switch (type) {
      case "danger":
        headerStyle = "danger";
        buttonStyle = "danger";
        break;
      default:
        headerStyle = "dark";
        buttonStyle = "primary";
    }

    const swalOptions = {
      title: options.title || options.text || settings.title,
      html: (options.title && options.text) || "",
      // icon: icon,
      showCancelButton: true,
      cancelButtonText: options.cancel || settings.cancel,
      confirmButtonText: options.commit || settings.commit,
      focusConfirm: focusElement === "confirm",
      focusCancel: focusElement === "cancel",
      customClass: {
        container: `DataConfirmModal DataConfirmModal--${type}`,
        title: `card-header bg-${headerStyle} text-white my-0 w-100`,
        confirmButton: `btn btn-${buttonStyle} waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    if (options.verify) {
      swalOptions.input = "text";
      if (options.verifyLabel)
        swalOptions.html += `<br/>${options.verifyLabel}`;

      swalOptions.showLoaderOnConfirm = true;
      swalOptions.allowOutsideClick = () => !Swal.isLoading();

      swalOptions.inputAttributes = {
        autocapitalize: "off",
        "data-verify": "true",
      };
      swalOptions.inputPlaceholder = options.verify;
      swalOptions.preConfirm = (result) => {
        if (result === null || result === "" || result === undefined) {
          Swal.showValidationMessage(`Verification is required`);
        } else if (result !== options.verify) {
          Swal.showValidationMessage(
            `${I18n.t("type")} "${options.verify}" ${I18n.t("to_confirm")}`
          );
        }
      };
    }

    return Swal.fire(swalOptions);
  }

  static start() {
    Rails.delegate(
      document,
      DataConfirmModal.settings.elements.join(", "),
      "confirm",
      (e) => {
        const element = e.target;
        DataConfirmModal.buildModal(element).then((result) => {
          if (result.value) {
            // User clicked confirm button
            element.removeAttribute("data-confirm");
            element.click();
          }
        });

        return false;
      }
    );
  }
}
