import ApplicationController from "application_controller";
import Swal from "sweetalert2";

export default class extends ApplicationController {
  static targets = ["panClosed", "panOpened", "panActions", "btnSubmit"];

  static values = {
    expanded: Boolean,
  };

  initialize() {
    this.bindElementController();
    this._hasInput = false;
    this._inputListenersAdded = false;

    this._expandedPreviously = false;
    this._expandedManually = false;
  }

  connect() {
    this.panActionsTarget.classList.add("d-none");

    if (this.expanded) this.onOpen();
  }

  disconnect() {
    this.removeInputListeners();
  }

  addInputListeners = () => {
    if (!this.editor) return;
    if (this._expandedManually) setTimeout(() => this.editor.focus(), 300);

    if (this._inputListenersAdded) return;

    this.editor.on("input", this.onInput);
    this.editor.on("Change", this.onInput);
    this._inputListenersAdded = true;
  };

  removeInputListeners = () => {
    this._inputListenersAdded = false;
    if (!this.editor) return;

    this.editor.off("input", this.onInput);
    this.editor.off("Change", this.onInput);
  };

  // events ..................................................................

  onOpen = () => {
    this._expandedManually = true;
    this.toggleOpened(true);
  };

  onEditorInit = (tinyCtrl) => {
    this._editor = tinyCtrl.editor;
    this.addInputListeners();
  };

  onCancel = () => {
    this.toggleOpened(false);
  };

  onCancelModal = () => {
    Swal.close();
  };

  onInput = (e) => {
    const editor = this.editor || e.target;

    this._hasInput = editor.getContent({ format: "text" }).trim().length > 0;
    if (this._expandedPreviously) this.toggleActions(true);
  };

  onFocusAndEdit = (fn) => {
    this.scrollTo(this.element);

    fn(this.editor);
  };

  // methods: private ..........................................................

  get expanded() {
    return this.hasExpandedValue && this.expandedValue;
  }

  get editor() {
    return this._editor;
  }

  // render ..................................................................

  toggleOpened = (active) => {
    this.setVisibility(active);

    if (active) {
      this._expandedPreviously = true;
      this.addInputListeners();
    } else {
      this.removeInputListeners();
    }

    this.toggleActions(active);
  };

  setVisibility = (visible) => {
    if (this.hasPanClosedTarget)
      this.panClosedTarget.classList.toggle("d-none", visible);

    if (!this.hasPanOpenedTarget) return;

    if (visible) {
      if (this.expanded) this.panOpenedTarget.classList.remove("d-none");
      else this.highlightToggle(this.panOpenedTarget, true);
    } else {
      this.panOpenedTarget.classList.add("d-none");
    }
  };

  toggleActions = (opened) => {
    const active = opened && this._hasInput;
    const shown = this.hasBtnSubmitTarget ? opened : active;

    if (this.hasBtnSubmitTarget) this.btnSubmitTarget.disabled = !active;

    this.panActionsTarget.classList.toggle("d-none", !shown);
    this.panActionsTarget.classList.toggle("d-flex", shown);
  };
}
