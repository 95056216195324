import ApplicationController from "application_controller";
import Rails from "@rails/ujs";

export default class extends ApplicationController {
  static targets = [
    "panLoading",
    "panForm",
    "btnImport",
    "btnCreateBlank",
    "btnCancel",
    "selectProduct",
    "panChkDesc",
    "panVariant",
    "panVariantLoading",
    "panVariantSelect",
    "selectVariant",
    "panGrouped",
  ];

  initialize() {
    this._productsUrl = this.element.dataset.productsUrl;

    this.includeDesc = true;
    this.resetForm();
  }

  resetForm() {
    this.selectedProductId = null;
    this.selectedVariantId = null;
    this.isVariable = false;
    this._selectedSplitGroup = false;
    this._isGrouped = false;
  }

  connect() {
    $(this.selectProductTarget).on("select2:select", this.onSelectProduct);
    $(this.selectVariantTarget).on("select2:select", this.onSelectVariant);

    $(this.element).on("ajax:before", this.onAjaxStart);
    $(this.element).on("ajax:complete", this.onAjaxEnd);
    $(this.element).on("ajax:success", this.onNewItemCreated);

    this.updateUI();
  }

  onAjaxStart = () => {
    this.element.disabled = true;
    this.panLoadingTarget.classList.remove("d-none");
    this.panFormTarget.classList.add("d-none");
    this.highlight(this.panLoadingTarget);
  };

  onAjaxEnd = () => {
    this.element.disabled = false;
    this.panLoadingTarget.classList.add("d-none");
    this.panFormTarget.classList.remove("d-none");
    this.btnCancelTarget.click();
  };

  onNewItemCreated = () => {
    this.element.dispatchEvent(new Event("item:added", { bubbles: true }));
    this.element.dispatchEvent(new Event("total:changed", { bubbles: true }));
    if (this._isGrouped) {
      this.reloadPanGrouped();
    }
  };

  onSelectProduct = (e) => {
    const { id, element } = e.params.data;
    const productType = element.dataset.t;
    this.selectedProductId = id;
    this.isVariable = id && productType === "variable";
    this._isGrouped = id && productType === "grouped";

    this.panChkDescTarget.classList.toggle("d-none", !id);
    this.updateProductVariants();
    this.updateGroupedProduct();
    this.updateUI();
  };

  onSelectVariant = (e) => {
    const { id } = e.params.data;
    this.selectedVariantId = id;

    this.updateUI();
  };

  onSelectGrouped(e) {
    this._selectedSplitGroup = e.target.value === "splitGroup";
    this.updateImportParams();
  }

  onIncludeDescChange = (e) => {
    this.includeDesc = e.target.checked;
  };

  onVariantsLoaded = (variants) => {
    const $select = $(this.selectVariantTarget);
    $.each(variants, (index, item) => {
      $select.append(`<option value='${item.id}'>${item.name}</option>`);
    });

    if (variants.length) this.selectedVariantId = variants[0].id;
    else this.selectedVariantId = null;

    this.updateUI();
  };

  onBtnCancel = () => {
    $(this.selectProductTarget).val(null).trigger("change");

    const $select = $(this.selectVariantTarget);
    $select.val(null).trigger("change");
    $select.empty();

    this.resetForm();

    this.panVariantTarget.classList.add("d-none");
    this.panGroupedTarget.classList.add("d-none");
    this.updateUI();
  };

  // methods: ui update .......................................................

  updateProductVariants() {
    const $select = $(this.selectVariantTarget);
    $select.val(null).trigger("change");
    $select.empty();

    this.panVariantTarget.classList.toggle("d-none", !this.isVariable);
    this.highlight(this.panVariantTarget);

    if (!this.isVariable) return;

    this.updateUIVariantLoading(true);

    Rails.ajax({
      type: "GET",
      url: `${this._productsUrl}/${this.selectedProductId}/ui/variants`,
      complete: () => {
        this.updateUIVariantLoading(false);
      },
      success: (data) => {
        this.onVariantsLoaded(data);
      },
      dataType: "json",
    });
  }

  updateGroupedProduct() {
    this.panGroupedTarget.classList.toggle("d-none", !this._isGrouped);
    if (!this._isGrouped) return;

    this.highlight(this.panGroupedTarget);
  }

  updateBtnImportState() {
    this.btnImportTarget.disabled =
      !this.selectedProductId || (this.isVariable && !this.selectedVariantId);

    this.btnImportTarget.classList.toggle(
      "disabled",
      this.btnImportTarget.disabled
    );
  }

  updateUIVariantLoading(loading = false) {
    this.panVariantLoadingTarget.classList.toggle("d-none", !loading);
    this.panVariantSelectTarget.classList.toggle("d-none", loading);
  }

  updateUI() {
    this.updateBtnImportState();
  }

  // methods: private .........................................................

  get selectedProductId() {
    return this._selectedProductId;
  }

  set selectedProductId(value) {
    this._selectedProductId = value;
    this.updateImportParams();
  }

  get isVariable() {
    return this._isVariable;
  }

  set isVariable(value) {
    this._isVariable = value;
  }

  get includeDesc() {
    return this._includeDesc;
  }

  set includeDesc(value) {
    this._includeDesc = value;
    this.updateImportParams();
  }

  get selectedVariantId() {
    return this._selectedVariantId;
  }

  set selectedVariantId(value) {
    this._selectedVariantId = value;
    this.updateImportParams();
  }

  reloadPanGrouped() {
    const content = this.panGroupedTarget.innerHTML;
    this.panGroupedTarget.innerHTML = content;
    if (this._selectedSplitGroup) {
      this._selectedSplitGroup = false;
      this.updateImportParams();
    }
  }

  updateImportParams = () => {
    this.btnImportTarget.dataset.params = new URLSearchParams({
      product_id: this.selectedProductId || "",
      variant_id: this.selectedVariantId || "",
      split_group: this._selectedSplitGroup || false,
      include_desc: this.includeDesc,
    }).toString();
  };
}
