import { Controller } from "stimulus";
import Push from "push.js";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = ["count"];

  connect() {
    this.refreshCountTarget(this.unopenedCount);
    this.listenDispatchEvent();
  }

  disconnect() {
    this.element.removeEventListener("event_occurred", this.onEventOccurred);
  }

  listenDispatchEvent() {
    this.element.addEventListener(
      "event_occurred",
      this.onEventOccurred,
      false
    );
  }

  onEventOccurred = (e) => {
    const notification = e.detail;

    if (notification.unopenedCount)
      this.unopenedCount = notification.unopenedCount;

    this.showToast(notification);
    this.showPush(notification);
  };

  showToast(notification) {
    const options = {
      position: "bottom-end",
      showConfirmButton: false,
      html: notification.body,
      timer: 8000,
      timerProgressBar: true,
      toast: true,
    };

    if (notification.icon)
      options.iconHtml = `<img href='${notification.icon}' />`;

    Swal.fire(options);
  }

  showPush(notification) {
    const title = $(notification.body)
      .find(".notification-title-value")
      .first()
      .text();
    Push.create(`QoteIt: ${title}`, { timeout: 5000 });
  }

  refreshCountTarget(count) {
    if (count > 0)
      $(this.countTarget).html(
        `<span class='badge badge-danger badge-pill noti-icon-badge'>${count}</span>`
      );
    else $(this.countTarget).html("");
  }

  get unopenedCount() {
    return parseInt(this.data.get("unopenedCount"), 10);
  }

  set unopenedCount(value) {
    this.data.set("unopenedCount", value);
    this.refreshCountTarget(value);
  }
}
