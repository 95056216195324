import ApplicationController from "application_controller";
import Swal from "sweetalert2";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = ["btnAddContact", "template", "wrapper", "editButton"];

  initialize() {
    this.showMode = this.data.get("showMode") === "true";
    this.showSubmit = this.data.get("submit") === "true";
    this._originalForms = {};
  }

  connect() {
    if (!this.showMode) this.initEditMode();
  }

  // events -------------------------------------------------------------------

  onAddContact(e) {
    e.preventDefault();

    const content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.btnAddContactTarget.insertAdjacentHTML("beforebegin", content);

    if (this.showMode) this.element.classList.add("js-editing");

    this.highlightLast(
      this.wrapperTarget.querySelectorAll(".customer-contact--editing")
    );
  }

  onCancel(e) {
    e.preventDefault();

    const item = e.target.closest(".customer-contact");
    if (this.showMode) this.element.classList.remove("js-editing");

    if (this.newRecord(item)) {
      this.highlightRemoval(item, () => item.remove());
      return;
    }

    item.classList.remove("customer-contact--editing");
    this.highlight(item);

    const id = this._contactId(item);

    if (!this._originalForms[id]) {
      console.warn("onCancel -- _originalForms missing", { id });
      return;
    }

    item.querySelector(
      ".customer-contact-form"
    ).innerHTML = this._originalForms[id];
  }

  onEditContact(e) {
    e.preventDefault();

    const item = e.target.closest(".customer-contact");
    item.classList.add("customer-contact--editing");

    this._saveOriginalForm(item);
    e.target.display = "none";

    if (this.showMode) this.element.classList.add("js-editing");
    this.highlight(item);
  }

  onRemoveContact(e) {
    e.preventDefault();

    const swalOptions = {
      title: I18n.t("delete_confirm"),
      html: e.target.dataset.title,
      showCancelButton: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      focusCancel: true,
      customClass: {
        container: `DataConfirmModal DataConfirmModal--danger`,
        title: `card-header bg-danger text-white my-0 w-100`,
        confirmButton: `btn btn-danger waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value) return;
      const item = e.target.closest(".customer-contact");
      const id = this._contactId(item);

      this.highlightRemoval(item, () => {
        if (id) {
          // ajax remove
          item.querySelector("input[name*='_destroy']").value = 1;
          item.style.display = "none";
          if (this.showMode) this._submitForm(item);
        } else {
          // remove from dom only
          item.parentNode.removeChild(item);
        }
      });
    });
  }

  // methods: private ..........................................................

  _saveOriginalForm(item) {
    const id = item.getAttribute("data-contact-id");

    if (this._originalForms[id]) return;

    this._originalForms[id] = item.querySelector(
      ".customer-contact-form"
    ).innerHTML;
  }

  _submitForm(item) {
    item.closest(".customer-form").submit();
  }

  _contactId(item) {
    return item.getAttribute("data-contact-id");
  }

  newRecord(item) {
    return item.hasAttribute("data-contact-new");
  }

  initEditMode() {
    this.editButtonTargets.forEach((x) => {
      const item = x.closest(".customer-contact");
      item.classList.add("customer-contact--editing");
    });
  }
}
