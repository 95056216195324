import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import { validateEmail } from "../../lib/strings";

export default class extends Controller {
  static values = { otpUrl: String };

  static targets = [
    "email",
    "emailIconNormal",
    "emailIconSpinner",
    "panOtp",
    "submit",
  ];

  connect() {
    this._otpRequired = false;
    this._loading = false;
    this._email = null;

    this._interval = setInterval(this._checkChanges, 300);
  }

  disconnect() {
    if (!this._interval) return;
    clearInterval(this._interval);
    this._interval = null;
  }

  _checkChanges = () => {
    if (this._email === this.emailTarget.value) return;

    this._email = this.emailTarget.value;
    this.onEmailChanged();
  };

  // methods - private --------------------------------------------------------

  onEmailChanged = () => {
    const email = this.emailTarget.value;
    if (validateEmail(email)) this._checkOtpRequired(email);
  };

  _checkOtpRequired = (email) => {
    this._otpRequired = false;
    this._emailLoading(true);
    Rails.ajax({
      type: "GET",
      url: `${this.otpUrlValue}`,
      data: new URLSearchParams({ email }).toString(),
      error: (e) => {
        console.warn("_checkOtpRequired -- error", { e });
      },
      success: (data) => {
        this._otpRequired = data.otp_required;
        this._refreshUi();
      },
      complete: () => {
        this._emailLoading(false);
      },
    });
  };

  _emailLoading = (loading) => {
    this._loading = loading;
    this.emailIconNormalTarget.classList.toggle("d-none", loading);
    this.emailIconSpinnerTarget.classList.toggle("d-none", !loading);
    this._refreshUi();
  };

  _refreshUi = () => {
    // this.submitTarget.disabled = this._loading;
    this.panOtpTarget.classList.toggle("d-none", !this._otpRequired);
  };
}
