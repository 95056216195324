import { Controller } from "stimulus";

/*
 * my--profile-edit
 */
export default class extends Controller {
  static targets = ["destroy", "timezoneGuess", "timezoneSelect"];

  connect() {
    this._dropifyMount();
    this._guessTimeZone();
  }

  disconnect() {
    this._dropifyUnMount();
  }

  // methods - private --------------------------------------------------------

  _dropifyMount() {
    this._dropify = $(".dropify").dropify();

    this._dropify.on("dropify.afterClear", () => {
      this.destroyTarget.value = "true";
    });

    this._dropify.on("dropify.fileReady", (event, success) => {
      if (!success) return;

      this.destroyTarget.value = "false";
    });
  }

  _dropifyUnMount() {
    this._dropify.each(() => {
      const instance = $(this).data("dropify");
      if (instance) instance.destroy();
    });
  }

  _guessTimeZone() {
    if (!this.hasTimezoneGuessTarget || this.timezoneGuessTarget.value) return;

    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneGuessTarget.value = tz;
  }
}
