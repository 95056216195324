import ApplicationController from "application_controller";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "ddUpload",
    "hiddenBlobId",
    "hiddenBlobUrl",
    "fileInput",
    "selCoverPosition",
    "selCoverRepeat",
    "panCoverSize",
    "txtCoverSize",
    "txtCoverColor",
  ];

  connect() {
    this._hasFile = !!this.hiddenBlobIdTarget.value;
    this._uploadUrl = this.data.get("uploadUrl");
    this.initRangeSlider();
    this.render();
    this.dispatch();
  }

  disconnect() {
    this.destroyRangeSlider();
  }

  initRangeSlider = () => {
    let val = parseInt(this.txtCoverSizeTarget.value || "100", 10);
    if (val > 100) val = 100;

    this._uiRangeSliders = $(this.txtCoverSizeTarget).ionRangeSlider({
      grid: true,
      skin: "flat",
      min: 5,
      max: 100,
      from: val,
      prefix: I18n.t("range_slider.prefix"),
      postfix: I18n.t("range_slider.postfix"),
      onFinish: this.onChange,
    });
  };

  destroyRangeSlider = () => {
    if (this._uiRangeSliders) this._uiRangeSliders.destroy();
  };

  // events ...................................................................

  onPositionChange = () => {
    this.render();
    this.dispatch();
  };

  onRepeatChange = () => {
    this.render();
    this.dispatch();
  };

  onChange = () => {
    this.dispatch();
  };

  onUpload = () => {
    this.fileInputTarget.click();
  };

  onImageSelected = () => {
    const input = this.fileInputTarget;
    this._hasFile = !!(input.files && input.files[0]);

    if (this._hasFile) this.sendFile(input.files[0]);
    else this.render();
  };

  onRemoveImage = () => {
    this._hasFile = false;
    this.fileInputTarget.value = "";
    this.hiddenBlobIdTarget.value = "";
    this.hiddenBlobUrlTarget.value = "";
    this.dispatch();

    this.render();
  };

  onUploaded = (success, response = null) => {
    if (success) {
      const rv = JSON.parse(response);

      this.hiddenBlobIdTarget.value = rv.id;
      this.hiddenBlobUrlTarget.value = rv.location;
      this.selCoverPositionTarget.value = "cover";

      this.dispatch();
    }

    this.setLoading(false);
  };

  // methods: ui update .......................................................

  render = () => {
    this.renderColor();
    this.renderPosition();
    this.renderRepeat();
    this.renderSize();
  };

  renderColor = () => {
    // this.txtCoverColorTarget.classList.toggle("d-none", !this.showPosition);
  };

  renderPosition = () => {
    this.selCoverPositionTarget.classList.toggle("d-none", !this.showPosition);
  };

  renderRepeat = () => {
    this.selCoverRepeatTarget.classList.toggle("d-none", !this.showRepeat);
  };

  renderSize = () => {
    this.panCoverSizeTarget.classList.toggle("d-none", !this.showSize);
  };

  dispatch = () => {
    const e = new CustomEvent("qote:theme:cover:changed", {
      bubbles: true,
      detail: {
        url: this.hiddenBlobUrlTarget.value,
        blob_id: this.hiddenBlobIdTarget.value,
        position: this.selCoverPositionTarget.value,
        repeat: this.selCoverRepeatTarget.value,
        size: this.showSize && this.txtCoverSizeTarget.value,
        color: this.txtCoverColorTarget.value,
      },
    });

    this.element.dispatchEvent(e);
  };

  // getters ..................................................................

  get showPosition() {
    return !!this._hasFile;
  }

  get showRepeat() {
    return (
      this.showPosition &&
      this.selCoverPositionTarget.value &&
      this.selCoverPositionTarget.value !== "cover"
    );
  }

  get showSize() {
    return (
      this.showRepeat &&
      this.selCoverRepeatTarget.value &&
      this.selCoverRepeatTarget.value !== "no-repeat"
    );
  }

  // methods: private .........................................................

  sendFile = (file) => {
    this.setLoading(true);

    const uri = this._uploadUrl;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();

    xhr.open("POST", uri, true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        this.onUploaded(true, xhr.responseText);
      } else {
        this.onUploaded(false);
      }
    };

    fd.append("file", file);
    xhr.send(fd);
  };

  setLoading = (loading) => {
    if (loading) this.element.style.opacity = "0.4";
    else this.element.style.opacity = "1";

    this.render();
  };
}
