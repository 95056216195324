import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static values = {
    target: String,
  };

  connect() {
    this._target = document.querySelector(this.targetValue);
    this._target.classList.toggle("d-none", !this.element.checked);
    this.element.addEventListener("change", this.onChange);
  }

  disconnect() {
    this.element.removeEventListener("ajax:before", this.onChange);
  }

  // events ...................................................................

  onChange = () => {
    const { checked } = this.element;

    this.highlightToggle(this._target, checked);
  };
}
