import { Controller } from "stimulus";
import ApexCharts from "apexcharts";

export default class extends Controller {
  static targets = ["chart"];

  static values = { value: Number, total: Number, color: String };

  connect() {
    const options = {
      chart: {
        height: 120,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      series: [(100 * this.valueValue) / (this.totalValue || 1)],
      colors: [this.colorValue],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "40%",
          },
          dataLabels: {
            show: false,
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Label"],
    };

    this._chart = new ApexCharts(this.chartTarget, options);
    this._chart.render();
  }

  disconnect() {
    if (this._chart) this._chart.destroy();
  }
}
