import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["btnCheckoutClose", "panSuccess"];

  connect() {
    document.body.addEventListener("PaddleCallbackResult", this.onPaddleResult);
  }

  // events ...................................................................

  onPaddleResult = (e) => {
    const data = e.detail;

    switch (data.event) {
      case "Checkout.Close":
        this.onCheckoutClose(data);
        break;
      case "Checkout.Complete":
        // Google Tag Manager
        window.dataLayer.push({ event: "Checkout.Complete" });

        this.panSuccessTarget.classList.toggle("d-none", false);
        this.btnCheckoutCloseTarget.parentNode.removeChild(
          this.btnCheckoutCloseTarget
        );
        break;
      default:
    }
  };

  onCheckoutClose = () => {
    if (!this.hasBtnCheckoutCloseTarget) return;

    this.btnCheckoutCloseTarget.click();
  };
}
