import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["title", "content", "indicator"];

  connect() {
    this._hasContent =
      this.hasContentTarget && !this.isBlank(this.contentTarget.innerText);
    this._expanded = false;

    if (this._hasContent) {
      this.element.classList.add("q-expandable-wrapper");
      this.titleTarget.addEventListener("click", this.onToggleContent);
      this.titleTarget.classList.add("q-expandable");
    } else if (this.hasExpandedIndicatorTarget)
      this.indicatorTarget.classList.add("d-none");
  }

  disconnect() {
    if (this.hasContent)
      this.titleTarget.removeEventListener("click", this.onToggleContent);
  }

  onToggleContent = () => {
    this._expanded = !this._expanded;
    this.element.classList.toggle(
      "q-expandable-wrapper--expanded",
      this._expanded
    );
    this.contentTarget.classList.toggle("d-none", !this._expanded);
    this.indicatorTarget.classList.toggle("fa-rotate-180", this._expanded);
  };
}
