import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = ["selArea", "panFontOptions", "panCustomText", "panLogo"];

  connect() {
    const opt = this.selAreaTarget.selectedOptions[0];
    this.render(opt);
  }

  // events ...................................................................

  onSelAreaChange = (e) => {
    const opt = e.target.selectedOptions[0];
    this.render(opt);
  };

  // methods: ui update .......................................................

  render = (opt) => {
    this.renderFontOptions(opt);
    this.renderLogoOptions(opt);
  };

  renderFontOptions = (opt) => {
    this.panCustomTextTarget.classList.toggle(
      "d-none",
      !opt || opt.value !== "custom_text"
    );

    this.panFontOptionsTarget.classList.toggle(
      "d-none",
      !opt || opt.dataset.font !== "1"
    );
  };

  renderLogoOptions = (opt) => {
    this.panLogoTarget.classList.toggle("d-none", !opt || opt.value !== "logo");
  };

  // getters ..................................................................
}
