import Swal from "sweetalert2";
import ApplicationController from "application_controller";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  static targets = [
    "newItemBtnWrapper",
    "newItemFormWrapper",
    "newItemForm",
    "items",
  ];

  connect() {
    $(this.newItemFormWrapperTarget).on("item:added", this.onNewItemCreated);
  }

  onAddItem = (e) => {
    e.preventDefault();
    this.toggleNewItemForm(true);
  };

  onBtnCancel = (e) => {
    e.preventDefault();
    this.toggleNewItemForm(false);
  };

  onNewItemCreated = () => {
    this.recomputePositions();
  };

  onRemoveItem = (e) => {
    e.preventDefault();

    const item = e.target.closest(".quotation-items-row");
    const title = item.querySelector(".quotation-items-name").value;

    const swalOptions = {
      title: I18n.t("delete_confirm"),
      html: title,
      showCancelButton: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      focusCancel: true,
      customClass: {
        container: `DataConfirmModal DataConfirmModal--danger`,
        title: `card-header bg-danger text-white my-0 w-100`,
        confirmButton: `btn btn-danger waves-effect waves-light`,
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value) return;
      const id = item.dataset.itemId;

      this.highlightRemoval(item, () => {
        if (id) {
          // ajax remove
          item.querySelector("input[name*='_destroy']").value = 1;
          item
            .querySelectorAll("input:not([type='hidden'])")
            .forEach((x) => x.parentNode.removeChild(x));
          item.style.display = "none";
        } else {
          // remove from dom only
          item.parentNode.removeChild(item);
        }

        e.target.dispatchEvent(new Event("total:changed", { bubbles: true }));
      });
    });
  };

  // methods: private .........................................................

  toggleNewItemForm(show = false) {
    this.newItemBtnWrapperTarget.classList.toggle("d-none", show);
    this.newItemFormWrapperTarget.classList.toggle("d-none", !show);

    if (show)
      this.highlight(this.newItemFormWrapperTarget.querySelector(".card"));
  }

  recomputePositions() {
    const rows = this.itemsTarget.querySelectorAll("tr");

    let i = 1;
    rows.forEach((row) => {
      this.recomputePosition(row, i);
      i += 1;
    });
  }

  recomputePosition(row, idx) {
    const field = this.data.has("optional")
      ? "items_optional_attributes"
      : "items_required_attributes";

    this.updateRailsFieldsNames(row, `quotation[${field}][`, idx);
  }
}
