import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    targetId: String,
    targetAttribute: String,
    thenFetch: String,
  };

  connect() {
    $(this.element).on("select2:select", this.onSelect);
    $(this.element).on("select2:clear", this.onClear);
  }

  disconnect() {}

  // events ...................................................................

  onSelect = (e) => {
    if (this.hasThenFetchValue) {
      this.callThenFetch(e.params.data);
    } else {
      const { id, text, element } = e.params.data;
      const data = JSON.stringify({
        id,
        text,
        dataset: element && element.dataset,
      });

      this.target.setAttribute(this.targetAttributeValue, data);
    }
  };

  onClear = () => {
    this.target.setAttribute(this.targetAttributeValue, "{}");
  };

  get target() {
    if (this._target) return this._target;

    this._target = document.getElementById(this.targetIdValue);
    return this._target;
  }

  // events ...................................................................

  callThenFetch = (selectedData) => {
    const url =
      selectedData[this.thenFetchValue] ||
      (selectedData.element &&
        selectedData.element.getAttribute(this.thenFetchValue));

    if (!url) {
      this.target.setAttribute(this.targetAttributeValue, "{}");
      return;
    }

    window.Rails.ajax({
      type: "GET",
      url,
      complete: () => {},
      success: ({ data }) => {
        this.target.setAttribute(
          this.targetAttributeValue,
          JSON.stringify(data)
        );
      },
      dataType: "json",
    });
  };
}
