import ApplicationController from "application_controller";
import Swal from "sweetalert2";
import I18n from "lib/i18n";

export default class extends ApplicationController {
  initialize() {
    this._template = document.getElementById("tplStatus");
    this._url = this._template.dataset.url;
  }

  connect() {
    this._oldStatusId = this.element.dataset.statusId;
    this._id = this.element.dataset.id;
    this.element.addEventListener("click", this.onShow);
  }

  onDidOpen = (el) => {
    this.scrollTo(el);
  };

  onShow = (e) => {
    e.preventDefault();

    const html = this._template.innerHTML;
    const oldStatusId = this._oldStatusId;
    const { _id, _url } = this;

    const swalOptions = {
      title: I18n.t("change_status.title", { title: e.target.dataset.title }),
      html,
      showCancelButton: true,
      focusCancel: true,
      showLoaderOnConfirm: true,
      confirmButtonText: I18n.t("data_confirm.commit"),
      cancelButtonText: I18n.t("data_confirm.cancel"),
      customClass: {
        container: "DataConfirmModal",
        title: "card-header bg-dark text-white my-0 w-100",
        confirmButton: "btn btn-primary waves-effect waves-light",
        cancelButton: "btn btn-light waves-effect waves-light",
        actions: "card-footer bg-light m-0",
      },
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        return document.getElementById("new_status_id").value;
      },
      willOpen: (modal) => {
        const $select = $(modal.querySelector("#new_status_id"));
        $select.val(oldStatusId).trigger("change");
      },
      didOpen: this.onDidOpen,
    };

    Swal.fire(swalOptions).then((result) => {
      if (!result.value || result.value === this._oldStatusId) return;

      const link = document.getElementById("hiddenBtnChangeStatus");
      link.href = `${_url}/${_id}`;
      link.dataset.params = `quotation[status_id]=${result.value}&return_to_index`;

      link.click();
    });
  };
}
