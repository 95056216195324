import ApplicationController from "application_controller";
import Swal from "sweetalert2";

export default class extends ApplicationController {
  connect() {
    if (this.isTurbolinksPreview) return;
    this.show();
  }

  disconnect() {
    this.element.remove();
  }

  show() {
    const options = {
      showConfirmButton: false,
      html: this.element.innerHTML,
      customClass: {
        container: "modal-container",
      },
      didOpen: this.onDidOpen,
      didDestroy: this.onDidDestroy,
      willClose: this.onWillClose,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    };

    Swal.fire(options);
  }

  onDidOpen = (el) => {
    this.scrollTo(el);
  };

  onDidDestroy = () => {
    this.element.remove();
  };

  onWillClose = (el) => {
    this.removeTinyMceEditors(el);
  };

  // helpers ..................................................................

  removeTinyMceEditors = (el) => {
    const editors = el.querySelectorAll("[data-controller=tiny]");
    editors.forEach((x) => {
      window.tinymce.remove(`#${x.id}`);
    });
  };
}
