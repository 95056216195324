import ApplicationController from "application_controller";

export default class extends ApplicationController {
  static targets = [
    "type",
    "paneAddon",
    "paneTypeStandalone",
    "paneTypeVariable",
    "paneTypeGrouped",
  ];

  connect() {
    this.initTypeRadio();
    this.updateUi();
  }

  initTypeRadio() {
    this.typeTargets.forEach((x) => {
      if (x.checked) this.selectedType = x.value;

      x.closest("label").addEventListener("click", () =>
        this.onProductTypeChange(x.value)
      );
    });
  }

  onChkAddonChange() {
    this.updatePaneAddon(true);
  }

  onProductTypeChange = (val) => {
    if (this.selectedType === val) return;

    this.selectedType = val;
    this.updatePaneTypes();
  };

  updatePaneAddon(inversed = false) {
    const { checked } = this.element.querySelector("input#product_is_addon");
    this.paneAddonTarget.classList.toggle(
      "d-none",
      inversed ? checked : !checked
    );
  }

  updatePaneTypes() {
    this.paneTypeStandaloneTarget.classList.toggle(
      "d-none",
      this.selectedType !== "standalone" && this.selectedType !== "grouped"
    );
    this.paneTypeVariableTarget.classList.toggle(
      "d-none",
      this.selectedType !== "variable"
    );
    this.paneTypeGroupedTarget.classList.toggle(
      "d-none",
      this.selectedType !== "grouped"
    );
  }

  updateUi() {
    this.updatePaneAddon();
    this.updatePaneTypes();
  }
}
